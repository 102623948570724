import React from 'react';
import AddressView from './AddressView';
import OrdersView from './OrdersView';
import WishlistView from './WishlistView';
import useAuthStore from '../../Store/AuthStore';
import useProductStore from '../../Store/ProductStore';
import useUserStore from '../../Store/UserStore';
import useBottomNavStore from '../../Store/BottomNavStore';
import useCartStore from '../../Store/CartStore';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Paper,
  Typography,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  BottomNavigation,
  BottomNavigationAction
} from '@mui/material';
import {
  Person,
  ShoppingCart,
  StorefrontRounded,
  ExpandLess,
  ExpandMore,
  ArrowBack,
  AccountCircleRounded,
  ShoppingBasketRounded,
  LocationOn,
  Bookmark
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

/* user profile mobile styles */
const useMobStyles = makeStyles(theme => ({
  box: {
    display: 'block',
    backgroundColor: 'var(--bodyBg)',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  listButton: {
    zIndex: 50
  },
  illustrationBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%'
  },
  illustration: {
    width: '20em',
    opacity: '0.8',
    transform: 'translateY(-20%)'
  },
  shopNow: {
    textDecoration: 'none',
    listStyle: 'none',
    transform: 'translateY(-160%)'
  }
}));

/* User Profile Mobile View */
function UserProfileMb() {
  /* zustand vars */
  const { navtab, setNavTab } = useBottomNavStore();
  const { userData, allOrders, watchList } = useUserStore();
  const { setAuth, setUserB2b } = useAuthStore();
  const { cart } = useCartStore();
  /* zustand vars */

  const [openOrders, setOpenOrders] = React.useState(false);
  const [openAddress, setOpenAddress] = React.useState(false);
  const [openWatchlist, setOpenWatchlist] = React.useState(false);

  const history = useHistory();

  const classes = useMobStyles();

  /*  handling isolated tabs toggling  */
  const handleClickOrders = () => {
    setOpenOrders(!openOrders);
  };
  const handleClickAddress = () => {
    setOpenAddress(!openAddress);
  };
  const handleClickWatchlist = () => {
    setOpenWatchlist(!openWatchlist);
  };

  return (
    <>
      <Box className={classes.box}>
        <AppBar position="static">
          <Toolbar>
            <Link to="/">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <ArrowBack onClick={() => setNavTab(0)} />
              </IconButton>
            </Link>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Profile
            </Typography>
            <Button
              color="inherit"
              onClick={() => {
                setAuth(false, () => {});
                setUserB2b(false);
                setNavTab(0);
                localStorage.clear();
              }}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ height: '76vh', overflowY: 'auto' }}>
          {userData && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderBottom: '1px solid lightgrey',
                height: '200px',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: 0.6
              }}
            >
              <IconButton>
                <AccountCircleRounded
                  style={{ fontSize: '90', color: '#9c5229' }}
                />
              </IconButton>
              <Typography
                variant="body1"
                component="h4"
                gutterBottom
                style={{
                  fontWeight: '450',
                  fontSize: '1.4rem',
                  color: '#9c5229'
                }}
              >
                {userData.firstName + ' ' + userData.lastName}
              </Typography>
              {userData.email && (
                <Typography
                  variant="body1"
                  component="h4"
                  gutterBottom
                  style={{ color: 'grey', fontWeight: '500' }}
                >
                  {userData.email}
                </Typography>
              )}
              <Typography
                variant="body1"
                component="h4"
                gutterBottom
                style={{ fontWeight: '500', marginBottom: '2px' }}
              >
                {'+91' + userData.phoneNumber}
              </Typography>
            </div>
          )}
          <List
            style={{ borderBottom: '1px solid lightgrey' }}
            sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              onClick={handleClickOrders}
              className={classes.listButton}
            >
              <ListItemIcon>
                <ShoppingBasketRounded />
              </ListItemIcon>
              <ListItemText primary="Your Orders" />
              {openOrders ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openOrders} timeout="auto" unmountOnExit>
              {allOrders.length > 0 ? (
                <OrdersView />
              ) : (
                <div className={classes.illustrationBox}>
                  <img
                    src="illustration\bgs order.png"
                    alt="orders illustration"
                    className={classes.illustration}
                  />
                  <Typography
                    variant="body1"
                    sx={{ transform: 'translateY(-480%)', fontSize: '.85em' }}
                  >
                    No Orders.
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      transform: 'translateY(-445%)',
                      fontSize: '.85em'
                    }}
                  >
                    Looks like you haven't placed any order yet!
                  </Typography>
                  <Link to="/" className={classes.shopNow}>
                    <Button
                      variant="contained"
                      // size="small"
                      onClick={() => setNavTab(0)}
                    >
                      Shop Now
                    </Button>
                  </Link>
                </div>
              )}
            </Collapse>
          </List>
          <List
            style={{ borderBottom: '1px solid lightgrey' }}
            sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              onClick={handleClickAddress}
              className={classes.listButton}
            >
              <ListItemIcon>
                <LocationOn />
              </ListItemIcon>
              <ListItemText primary="Your Address" />
              {openAddress ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openAddress} timeout="auto" unmountOnExit>
              <AddressView />
            </Collapse>
          </List>
          <List
            style={{ borderBottom: '1px solid lightgrey' }}
            sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              onClick={handleClickWatchlist}
              className={classes.listButton}
            >
              <ListItemIcon>
                <Bookmark />
              </ListItemIcon>
              <ListItemText primary="Your Wishlist" />
              {openWatchlist ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openWatchlist} timeout="auto" unmountOnExit>
              {watchList.length > 0 ? (
                <div className="products">
                  <WishlistView />
                </div>
              ) : (
                <div className={classes.illustrationBox}>
                  <img
                    src="illustration\bgswatchlist.png"
                    alt="address illustration"
                    className={classes.illustration}
                  />
                  <Typography
                    variant="body1"
                    sx={{ transform: 'translateY(-240%)', fontSize: '.85em' }}
                  >
                    Sorry! Your wishlist is empty.
                  </Typography>
                  <Link
                    to="/"
                    className={classes.shopNow}
                    style={{ marginTop: '10%' }}
                  >
                    <Button variant="contained" onClick={() => setNavTab(0)}>
                      Wishlist Now
                    </Button>
                  </Link>
                </div>
              )}
            </Collapse>
          </List>
        </div>
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            height: '80px',
            py: 2,
            background: 'white',
            zIndex: '100',
            width: '100%'
          }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={navtab}
            sx={{ px: 1 }}
            onChange={(event, newValue) => {
              setNavTab(newValue);
            }}
          >
            <BottomNavigationAction
              onClick={() => history.push('/')}
              label={<Typography variant="overline">Shop</Typography>}
              icon={<StorefrontRounded />}
            />
            <BottomNavigationAction
              onClick={() => {
                history.push('/cart');
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              }}
              label={<Typography variant="overline">Cart</Typography>}
              icon={
                <Badge badgeContent={cart.length} color="error">
                  <ShoppingCart />
                </Badge>
              }
            />
            <BottomNavigationAction
              onClick={() => history.push('/user')}
              label={<Typography variant="overline">Profile</Typography>}
              icon={<Person />}
            />
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
}
/* User Profile Mobile View */

export default UserProfileMb;
