import React from 'react';
import apple from '../Loading/loadingImages/apple.png';
import banana from '../Loading/loadingImages/bananas.png';
import carrot from '../Loading/loadingImages/carrot.png';
import cabbage from '../Loading/loadingImages/cabbage.png';
import eggplant from '../Loading/loadingImages/eggplant.png';
import grape from '../Loading/loadingImages/grape.png';
import lemon from '../Loading/loadingImages/lemon.png';
import mango from '../Loading/loadingImages/mango.png';
import pineapple from '../Loading/loadingImages/pineapple.png';
import tomato from '../Loading/loadingImages/tomato.png';
import watermelon from '../Loading/loadingImages/watermelon.png';
import './Loading.css';

const LOADING_IMAGES = [
  <img src={apple} alt="apple" />,
  <img src={banana} alt="banana" />,
  <img src={carrot} alt="carrot" />,
  <img src={cabbage} alt="cabbage" />,
  <img src={eggplant} alt="eggplant" />,
  <img src={grape} alt="grape" />,
  <img src={lemon} alt="lemon" />,
  <img src={mango} alt="mango" />,
  <img src={pineapple} alt="pineapple" />,
  <img src={tomato} alt="tomato" />,
  <img src={watermelon} alt="watermelon" />
];

const Loading = () => {
  const [index, setIndex] = React.useState({
    first: 0,
    middle: 1,
    last: 2
  });

  const showSlide = () => {
    setIndex(prev => {
      if (prev.first > LOADING_IMAGES.length - 2)
        return { first: 0, middle: 1, last: 2 };
      return {
        first: prev.first + 1,
        middle: prev.middle + 1,
        last: prev.last + 1
      };
    });
  };

  React.useEffect(() => {
    let slide = setInterval(showSlide, 400);
    return () => {
      clearInterval(slide);
    };
  }, [index]);

  return (
    <div className="loading-body">
      <div className="loadImgBox">
        {LOADING_IMAGES[index.first]}
        {/* {LOADING_IMAGES[index.middle]}
        {LOADING_IMAGES[index.last]} */}
      </div>
    </div>
  );
};
export default Loading;
