/* Validate AddressPopup Form inputs */
export const validateAddressForm = (values, pinValid) => {
  var phoneno = /^\d{10}$/;
  var validatePincode = /^\d{6}$/;
  let errors = {};

  if (!values.fullName) {
    errors.fullName = 'Required';
  }

  if (!values.phone.toString().match(phoneno)) {
    errors.phone = 'Invalid phone';
  }
  if (!values.landmark) {
    errors.landmark = 'Required';
  }
  if (!values.houseNo) {
    errors.houseNo = 'Required';
  }
  if (!values.pinCode.match(validatePincode)) {
    errors.pinCode = 'Invalid pincode';
  } else if (!pinValid) {
    errors.pinCode = 'Please select a different location with pincode';
  }

  return errors;
};
/* Validate AddressPopup Form inputs */

/* converting date object to local iso string */
export function toISOLocal(d) {
  const z = n => ('0' + n).slice(-2);
  const zz = n => ('00' + n).slice(-3);

  return (
    d.getFullYear() +
    '-' +
    z(d.getMonth() + 1) +
    '-' +
    z(d.getDate()) +
    'T' +
    z(d.getHours()) +
    ':' +
    z(d.getMinutes()) +
    ':' +
    z(d.getSeconds()) +
    '.' +
    zz(d.getMilliseconds()) +
    'Z'
  );
}
/* converting date object to local iso string */
