import React from 'react';
import './Address.css';
import useMiscStore from '../../Store/MiscStore';
import useMapStore from '../../Store/MapStore';
import { Button, Modal, Box } from '@mui/material';
import ShowMap from '../ShowMap/ShowMap';
import Proceed from './Proceed';
import EnterManually from './EnterManually';

/*AddressPopup*/
const AddressPopup = () => {
  /*Zustand Variables*/
  const { addAddress, setAddAddress, addressView, setAddressView } =
    useMiscStore();
  const { setMapState } = useMapStore();
  /*Zustand Variables*/

  return (
    <Modal
      open={addAddress}
      onClose={() => {
        setAddAddress(false);
        setAddressView(0);
      }}
    >
      <Box>
        <div className="address-form" autoComplete="off">
          {addressView === 0 && (
            <>
              <h2>Confirm your location</h2>
              <div>{<ShowMap setMapState={setMapState} />}</div>
              <Button
                style={{
                  background: 'var(--primary)',
                  color: 'white',
                  cursor: 'pointer',
                  margin: '2% auto 1%'
                }}
                variant="contained"
                type="submit"
                size="small"
                onClick={() => setAddressView(1)}
                endIcon={<>&#x276f;</>}
              >
                Proceed
              </Button>
              <div
                className="or-line"
                style={{ textAlign: 'center', fontSize: '0.8rem' }}
              >
                <span>or</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Button
                  style={{
                    cursor: 'pointer',
                    margin: '0 3em 2%'
                  }}
                  variant="outlined"
                  size="small"
                  type="submit"
                  onClick={() => setAddressView(2)}
                >
                  Enter Manually
                </Button>
                <Button
                  style={{
                    cursor: 'pointer',
                    margin: '0 0 2%'
                  }}
                  variant="outlined"
                  size="small"
                  type="submit"
                  onClick={() => {
                    setAddAddress(false);
                    setAddressView(0);
                  }}
                >
                  Choose from Existing
                </Button>
              </div>
            </>
          )}
          {addressView === 1 && <Proceed />}
          {addressView === 2 && <EnterManually />}
        </div>
      </Box>
    </Modal>
  );
};
/*AddressPopup*/

export default AddressPopup;
