const validate = values => {
  let errors = {};
  if (!values.text) {
    errors.text = 'Enter Email or mobileNumber';
  }
  if (!values.password) {
    errors.password = 'Enter password';
  }
  return errors;
};

export default validate;
