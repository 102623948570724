import React from 'react';
import SingleOrder from './SingleOrder';
import useUserStore from '../../Store/UserStore';

function OrdersView() {
  /* zustand vars */
  const { allOrders } = useUserStore();
  /* zustand vars */
  return (
    <>
      {allOrders.map(order => (
        <SingleOrder
          key={order.invoiceId}
          total={order.total}
          orderedOn={order.orderedOn}
          items={order.items}
          orderStatus={order.status}
          invoiceId={order.invoiceId}
        />
      ))}
    </>
  );
}

export default OrdersView;
