const validatePassword = values => {
  let errors = {};

  if (!values.firstname) {
    errors.firstname = 'First Name is required';
  }
  if (!values.lastname) {
    errors.lastname = 'Last Name is required';
  }
  if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Enter valid email';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 5) {
    errors.password = 'Password must be more than 5 characters';
  }
  if (!values.enterpriseName) {
    errors.enterpriseName = 'Enterprise Name is required';
  }
  if (
    values.gstNumber &&
    !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      values.gstNumber
    )
  ) {
    errors.gstNumber = 'Please enter a valid GST Number';
  }
  if (!values.requirementFrequency) {
    errors.requirementFrequency = 'Requirement Frequency is required';
  }
  if (!values.businessType) {
    errors.businessType = 'Business Type is required';
  }

  return errors;
};
export default validatePassword;
