import React, { useState, useEffect } from 'react';
import './Cart.css';
import Header from '../Header/Header';
import useAuthStore from '../../Store/AuthStore';
import useProductStore from '../../Store/ProductStore';
import useCartStore from '../../Store/CartStore';
import useMiscStore from '../../Store/MiscStore';
import useBottomNavStore from '../../Store/BottomNavStore';
import { Link, useHistory } from 'react-router-dom';
import SingleCartItem from './SingleCartItem';
import {
  Grid,
  Button,
  Typography,
  TextField,
  Box,
  Select,
  MenuItem,
  Modal
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from '@mui/styles';
import { axiosInstance } from '../../axios_assets';
import Footer from '../Footer/Footer';
import { toISOLocal } from '../../helpers';

/* constants */
const PRODUCTS_URL = `${process.env.REACT_APP_PRODUCTS_KEY}`;
/* constants */

const useStyles = makeStyles(theme => ({
  image: {
    width: '200px',

    [theme.breakpoints.down('sm')]: {
      width: '100px'
    }
  },
  icon: {
    fontSize: '13px',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px'
    },
    margin: ' 2px'
  },
  typo1: {
    color: 'black',
    fontWeight: '400'
  },
  typo2: {
    color: 'black',
    fontWeight: '500',
    textAlign: 'center'
  },
  instructions: {
    // margin: '5% 5% 0'
  },
  selectBox: {
    width: '100%',
    padding: '0 5% 0 17%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectors: {
    width: 'clamp(95%,10vw,100%)',
    // maxWidth: '100%',
    fontSize: '1em'
  },
  illustrationBox: {
    display: 'flex',
    alignItems: 'center',
    height: '70vh',
    [theme.breakpoints.down('md')]: {
      height: '60vh'
    }
  },
  illustration: {
    width: '30em',
    [theme.breakpoints.down('md')]: {
      width: '40vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '60vw'
    },
    '@media(max-width: 500px)': {
      width: '75vw'
    },
    '@media(max-width: 400px)': {
      width: '85vw'
    }
  },
  shopNow: {
    textDecoration: 'none',
    listStyle: 'none',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(-100%)'
    }
  }
}));

/*cart*/
const Cart = () => {
  const classes = useStyles();

  /*zustand variables*/
  const { setCart, cart, orderInstructions, setOrderInstructions } =
    useCartStore();
  const isAuth = useAuthStore(state => state.isAuth);
  const { products, setProducts } = useProductStore();
  let history = useHistory();
  const { tab, setTab, setShowSignUp } = useMiscStore();
  const { setNavTab } = useBottomNavStore(state => state);
  /*zustand variables*/

  /*useState*/
  const [open, setOpen] = useState(false);
  const [OSItems, setOSItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [checkout, setCheckout] = useState(false);
  const [isDateValid, setIsDateValid] = useState(false);
  const [hasChg, setHasChg] = useState(false);
  const [checkDate, setCheckDate] = useState(false);
  const [timeState, setTimeState] = useState({
    show612: true,
    showNextDay: false
  });
  const menuItems = ['6am-12pm', '3pm-9pm'];
  /*useState*/

  // set nav tab to cart tab
  useEffect(() => {
    setNavTab(1);
  }, []);

  useEffect(() => {
    checkout && isDateValid && CROS();
  }, [products]);

  useEffect(() => {
    handleTimeSlotLogic();
    const tSlot = orderInstructions.timeSlot;
    const dDate = orderInstructions.deliveryDate;
    const conditionOne = tSlot === '6am-12pm' && timeState.show612;
    const conditionTwo =
      new Date().getDate() < parseInt(dDate.slice(8, 10), 10) &&
      timeState.showNextDay;
    if (conditionOne || !conditionTwo) {
      setIsDateValid(true);
    } else {
      setIsDateValid(false);
    }
    setCheckDate(false);
  }, [checkDate]);

  /*totalPrice*/
  const subTotal = cart.reduce(
    (price, item) => price + item.newQuantity * item.amount,
    0
  );
  /*totalPrice*/

  function handleTimeSlotLogic() {
    const { show612, showNextDay } = handleTime();
    setTimeState({ show612, showNextDay });
    const date = new Date();
    if (showNextDay) date.setDate(date.getDate() + 1);
    setOrderInstructions({
      ...orderInstructions,
      deliveryDate: toISOLocal(date).slice(0, 10)
    });
  }

  /* Show timeslots logic */
  function handleTime() {
    const dStr = orderInstructions.deliveryDate;
    let d;
    if (hasChg) {
      d = new Date(
        dStr.slice(0, 4),
        parseInt(dStr.slice(5, 7)) - 1,
        dStr.slice(8, 10)
      );
    } else {
      d = new Date();
    }
    let show612 = true;
    let showNextDay = false;
    const hour = d.getHours();
    const min = d.getMinutes();
    const sec = d.getSeconds();
    if (hour >= 12 && min >= 0 && sec > 0) show612 = false;
    if (hour >= 21 && min >= 0 && sec > 0) showNextDay = true;
    if (showNextDay === true) show612 = true;
    console.log('show 612 and next day ', show612, showNextDay);
    return { show612, showNextDay };
  }
  /* Show timeslots logic */

  /*getProducts*/
  const getProducts = () => {
    axiosInstance
      .post(PRODUCTS_URL)
      .then(function (response) {
        // console.log(response.data);
        setProducts(response.data);
        // console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  /*getProducts*/

  /*CROS*/
  const CROS = () => {
    const OSproducts = cart.filter(cartItem => {
      const tempItem = products.find(
        product => product.productId === cartItem.itemId
      );
      return tempItem.product_status !== 'AT';
    });
    if (OSproducts.length > 0) {
      setOSItems(
        OSproducts.map(OSproduct => {
          return OSproduct.title;
        })
      );
      setOpen(true);
    } else {
      history.push('/checkout', { params: subTotal });
    }
  };
  /*CROS*/

  const flaws = cart.filter(
    item => item.newQuantity < item.minQuantity || item.newQuantity > 999
  );

  React.useEffect(() => {
    setCartTotal(subTotal);
  }, [subTotal]);

  const handleChange = e => {
    setOrderInstructions({
      ...orderInstructions,
      [e.target.name]: e.target.value
    });
  };

  if (cart.length === 0) {
    return (
      <>
        <Header />
        <div className="cart-outer-box" style={{ height: '100vh' }}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '30vh', paddingTop: '8%' }}
          >
            <div
              className={classes.illustrationBox}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img
                src="illustration/bgscart.png"
                alt="bgscart"
                className={classes.illustration}
              />
            </div>
            <Link to="/" className={classes.shopNow}>
              <Button
                variant="contained"
                style={{ padding: '10px' }}
                onClick={() => setNavTab(0)}
              >
                Shop-Now
              </Button>
            </Link>
          </Grid>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Header />
        <div className="cart-outer-box">
          <Grid
            container
            lg={12}
            md={12}
            xs={12}
            style={{
              width: '90%',
              margin: '0 auto',
              padding: '90px auto'
            }}
          >
            <Grid item lg={12} md={12} xs={12}>
              <Link to="/">
                <Button
                  variant="contained"
                  style={{
                    border: 'none',
                    background: '#9c5229',
                    textDecoration: 'none',
                    listStyle: 'none',
                    float: 'left',
                    padding: '9px 15px',
                    fontSize: '15px',
                    color: 'white',
                    margin: '20px'
                  }}
                  onClick={() => setNavTab(0)}
                >
                  <ArrowBackIosIcon
                    style={{ fontSize: '16px', color: 'white' }}
                  />
                  Continue-Shopping
                </Button>
              </Link>
            </Grid>

            <Grid
              item
              lg={5}
              md={12}
              sm={12}
              xs={12}
              style={{ padding: '10px', maxHeight: '50%', margin: '0 auto' }}
            >
              <Box sx={{ boxShadow: 2 }}>
                <Grid
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={2}
                  style={{ padding: '0 10px 0 0', margin: '10px 0' }}
                >
                  <Grid item xs={6}>
                    <Typography style={{ fontWeight: 500, color: '#9c5229' }}>
                      Cart Summary
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      variant="outlined"
                      style={{
                        color: 'black',
                        fontSize: '13px',
                        whiteSpace: 'nowrap'
                      }}
                      onClick={() => setCart([])}
                    >
                      Empty Cart
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.typo1}>Subtotal</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.typo2}>
                      Rs.{subTotal}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.typo1}>
                      Delivery Charges
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.typo2}>Rs.0</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.typo1}>
                      Total Payable
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.typo2}>
                      Rs.{subTotal}
                    </Typography>
                  </Grid>
                  {/* <div className={classes.selectBox} style={{ margin: '5% 0' }}> */}
                  <Grid item xs={6}>
                    <Typography className={classes.typo1}>
                      Delivery Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <TextField
                      type="date"
                      name="deliveryDate"
                      size="small"
                      value={orderInstructions.deliveryDate}
                      onChange={e => {
                        handleChange(e);
                        setHasChg(true);
                      }}
                      className={classes.selectors}
                    />
                  </Grid>
                  {/* </div> */}
                  {/* <div
                    className={classes.selectBox}
                    style={{ paddingLeft: '14%' }}
                  > */}
                  <Grid item xs={6}>
                    <Typography style={{ color: 'black', marginBottom: '3%' }}>
                      Delivery Timeslot
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Select
                      name="timeSlot"
                      size="small"
                      onChange={handleChange}
                      value={orderInstructions.timeSlot}
                      className={classes.selectors}
                    >
                      {menuItems
                        .filter((item, ind) => {
                          if (ind === 0 && !timeState.show612) return false;
                          return true;
                        })
                        .map(item => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                  </Grid>

                  {/* </div> */}
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label="Instructions"
                      placeholder="Any additional information regarding the Order (Optional)"
                      value={orderInstructions.instructions}
                      name="instructions"
                      onChange={handleChange}
                      multiline
                      minRows={3}
                      maxRows={5}
                      fullWidth
                      className={classes.instructions}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ padding: '2% 0' }}>
                    {isAuth ? (
                      <Button
                        variant="contained"
                        disabled={flaws.length > 0 || subTotal < 100}
                        onClick={() => {
                          getProducts();
                          setCheckDate(true);
                          setCheckout(true);
                        }}
                      >
                        {cart.length} Items | Checkout
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => setShowSignUp(true)}
                      >
                        Login
                      </Button>
                    )}
                    <Grid item xs={12}>
                      {subTotal < 500 && (
                        <Typography
                          style={{
                            color: 'black',
                            fontSize: '10px',
                            marginTop: '10px'
                          }}
                        >
                          cart value must be atleast Rs.500
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              lg={7}
              md={12}
              xs={12}
              style={{
                padding: '5px 20px',
                maxHeight: '450px',
                overflow: 'auto',
                marginBottom: '5px'
                // border:'1px solid black'
              }}
            >
              {cart.map(product => (
                <SingleCartItem {...product} key={product.productId} />
              ))}
            </Grid>
          </Grid>
        </div>
        <Footer />
        <RemoveModal open={open} setOpen={setOpen} OSItems={OSItems} />
      </>
    );
  }
};

/*RemoveModal*/
const RemoveModal = ({ open, setOpen, OSItems }) => {
  const { setCart, cart } = useCartStore();
  const { products } = useProductStore();

  /*filterOutOfStock*/
  const filterOS = () => {
    setCart(
      cart
        .filter(cartItem => {
          const tempItem = products.find(
            product => product.productId === cartItem.itemId
          );

          return tempItem.product_status !== 'OS';
        })
        .filter(cartItem => {
          const tempItem = products.find(
            product => product.productId === cartItem.itemId
          );

          return tempItem.product_status !== 'NA';
        })
    );
  };
  /*filterOutOfStock*/

  /*css style*/
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 4
  };
  /*css style*/

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="body1" sx={{ mb: 3 }}>
            Oops! <strong>{OSItems.join(', ')}</strong>
            {OSItems.length === 1 ? 'is' : 'are'} OUT-OF-STOCK
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <Button
              onClick={() => {
                filterOS();
                setOpen(false);
              }}
              variant="contained"
              size="small"
            >
              Remove
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
/*RemoveModal*/

export default Cart;
/*cart*/
