import { useLayoutEffect, useState } from 'react';

/*useWindowSize*/
export const useWindowSize = () => {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
/*useWindowSize*/

// custom hook for getting window width in px
//   const useWindowSize = () => {
//     const haswindow = typeof window !== 'undefined';

//     const getWindowWidth = () => {
//       const width = haswindow ? window.innerWidth : null;
//       return width;
//     };
//     const [windowWidth, setWindowWidth] = React.useState(getWindowWidth());

//     useEffect(() => {
//       if (haswindow) {
//         const handleWidth = () => {
//           setWindowWidth(getWindowWidth());
//         };
//         window.addEventListener('resize', handleWidth);
//       }
//     }, []);
//     return windowWidth;
//   };
