import React, { useEffect, useState } from 'react';
import './Signup.css';
import { TextField, Button } from '@mui/material';
import useMiscStore from '../../Store/MiscStore';
import { firebase, auth } from './Firebase';
// import axios from 'axios';
import { axiosInstance } from '../../axios_assets';
import { toast } from 'react-toastify';

const CHECK_EXISTING_URL = `${process.env.REACT_APP_CHECK_EXISTING_KEY}`;

const PASSWORD_UPDATE_URL = `${process.env.REACT_APP_PASSWORD_UPDATE_KEY}`;

function ForgotPasswordView() {
  const [values, setValues] = useState({
    updatedPassword: '',
    phoneNumber: ''
  });
  const [valid, setValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [isExisting, setIsExisting] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState();
  const { setFormView } = useMiscStore(state => state);
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState('');

  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  function validatePasswords() {
    const compare = passwords.newPassword.localeCompare(
      passwords.confirmPassword
    );
    if (compare === 0) {
      setValues({ ...values, updatedPassword: passwords.confirmPassword });
      setErrorMsg('');
    } else {
      setErrorMsg('Passwords do not match');
    }
  }

  const validatePass = () => {
    if (!passwords.newPassword) {
      setErrMsg('Please enter new password');
    } else if (passwords.newPassword.length < 5) {
      setErrMsg('Password must be more than 5 characters');
    } else {
      setErrMsg('');
    }
  };

  useEffect(() => {
    validatePass();
    validatePasswords();
  }, [passwords.newPassword, passwords.confirmPassword]);

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = e => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handleCheckUserExistsRequest = async () => {
    try {
      const response = await axiosInstance.post(CHECK_EXISTING_URL, {
        phoneNumber: values.phoneNumber
      });
      //console.log(response.data);

      //console.log('response received...');
      if (response.data === true) {
        setIsExisting('');
        //setValid(true);
        // console.log('exisitt');
        signin();
        //console.log('user registered: ', response.data);
      } else {
        setIsExisting('User does not exists');

        // verifyOTP();
      }
      //console.log('signin executed');
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  const handleChangePassword = () => {
    validatePass();
    if (errMsg !== '') return;
    setValues({ ...values, updatedPassword: passwords.confirmPassword });

    axiosInstance
      .post(PASSWORD_UPDATE_URL, {
        updatedPassword: values.updatedPassword,
        phoneNumber: values.phoneNumber
      })
      .then(function (response) {
        setFormView(0);
        toast.success('Your account password has been changed successfully');
      })
      .catch(e => {
        console.error(e);
      });
  };

  const signin = () => {
    //console.log('Inside signin ...');
    if (valid) {
      // console.log('in return....');
      //console.log('inside if...', isRegistered);
      return;
    }

    //console.log('yes i enetered', isExisting, isRegistered);
    // console.log('in otp verification....');
    let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    auth
      .signInWithPhoneNumber('+91' + values.phoneNumber, verify)
      .then(result => {
        setfinal(result);
        setshow(true);
        setValid(false);
      })
      .catch(err => {
        alert(err);
        window.location.reload();
      });
  };

  const ValidateOtp = () => {
    //console.log('here   ', otp);
    if (otp === '' || final === null) {
      setError(true);
      setErrorMessage('Invalid OTP');
      return;
    }
    setError(false);
    setErrorMessage('');
    final
      .confirm(otp)
      .then(result => {
        setValid(true);
        setshow(false);
      })
      .catch(err => {
        setError(true);
        setErrorMessage('Invalid OTP');
      });
  };

  return (
    <>
      <div className="pop-title">Reset Password</div>
      {!valid ? (
        <>
          <div style={{ display: !show ? 'block' : 'none' }}>
            <br />
            <TextField
              label="Mobile Number"
              onChange={handleChange}
              sx={{ width: '100%', my: '2%' }}
              type="tel"
              value={values.phoneNumber}
              name="phoneNumber"
            />
            <br />
            <div id="recaptcha-container"></div>

            {error && <p className="error">{errorMessage}</p>}
            {isExisting !== '' && <p className="error">{isExisting}</p>}
            <Button
              variant="contained"
              sx={{ my: '2%', mx: 'auto' }}
              onClick={handleCheckUserExistsRequest}
            >
              Send OTP
            </Button>
          </div>
          <div style={{ display: show ? 'block' : 'none' }}>
            <TextField
              type="text"
              placeholder={'Enter your OTP'}
              sx={{ width: '100%', my: '2%' }}
              onChange={e => {
                setOtp(e.target.value);
              }}
            />
            <br />
            <Button
              variant="contained"
              sx={{ my: '2%', mx: 'auto' }}
              onClick={ValidateOtp}
            >
              Verify
            </Button>
            {error ? <p className="error">{errorMessage}</p> : <div></div>}
          </div>
        </>
      ) : (
        <>
          <TextField
            style={{ marginTop: '10px' }}
            label="New Password"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
            type="password"
            name="newPassword"
          />
          {passwords.newPassword.length > 0 && errMsg !== '' && (
            <p className="error">{errMsg}</p>
          )}
          <TextField
            style={{ marginTop: '10px' }}
            label="Confirm Password"
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
            type="text"
            name="confirmPassword"
          />
          {passwords.confirmPassword.length > 0 && errorMsg !== '' && (
            <p className="error">{errorMsg}</p>
          )}
          <Button
            variant="contained"
            sx={{ my: '2%', mx: 'auto' }}
            onClick={handleChangePassword}
          >
            Change Password
          </Button>
        </>
      )}
    </>
  );
}

export default ForgotPasswordView;
