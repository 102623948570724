import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';
import App from './App';
// import axios from 'axios';
import { axiosInstance } from './axios_assets';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TOKEN_KEY } from './Constants';

const theme = createTheme({
  palette: {
    primary: {
      light: '#ae5a2a',
      main: '#9c5229',
      dark: '#884824',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ae5a2a',
      main: '#9c5229',
      dark: '#884824',
      contrastText: '#fff'
    }
  },
  spacing: 8
});

const LOGIN_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN_KEY}`;
const PASSWORD_UPDATE_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PASSWORD_UPDATE_KEY}`;
const CHECK_EXISTING_URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CHECK_EXISTING_KEY}`;

axiosInstance.interceptors.request.use(
  request => {
    // console.log(request);
    request.url = `${process.env.REACT_APP_BASE_URL}${request.url}`;
    if (localStorage.getItem(TOKEN_KEY)) {
      request.headers.Authorization = `Bearer ${localStorage.getItem(
        TOKEN_KEY
      )}`;
    }
    return request;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    // console.log(response);
    return response;
  },
  error => {
    // console.log(error);
    if (error.response.status === 401) {
      //place your reentry code
      localStorage.removeItem(TOKEN_KEY);
      //need to redirect to login page
      // if (
      //   error.config.url !==
      //     `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN_KEY}` ||
      //   error.config.url !==
      //     `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PASSWORD_UPDATE_KEY}` ||
      //   error.config.url !==
      //     `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CHECK_EXISTING_KEY}`
      // ) {
      //   window.location.assign(window.location.origin);
      // }
      // console.log(LOGIN_URL);
      // console.log(PASSWORD_UPDATE_URL);
      // console.log(CHECK_EXISTING_URL);
      switch (error.config.url) {
        case LOGIN_URL:
          break;
        case PASSWORD_UPDATE_URL:
          break;
        case CHECK_EXISTING_URL:
          break;
        default:
          window.location.assign(window.location.origin);
      }
    }
    return Promise.reject(error);
  }
);

const root = document.documentElement;
function handleScroll() {
  // Do something on scroll
  const scrollToTopBtn = document.querySelector('.scroll-top');
  let scrollTotal = root.scrollHeight - root.clientHeight;
  if (root.scrollTop / scrollTotal > 0.2) {
    // Show button
    scrollToTopBtn?.classList.add('showBtn');
  } else {
    // Hide button
    scrollToTopBtn?.classList.remove('showBtn');
  }
}
document.addEventListener('scroll', handleScroll);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ToastContainer
      position="bottom-center"
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover
      autoClose={1500}
      limit={1}
    />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);
