import React, { useEffect, useState } from 'react';
import './Signup.css';
import { CreateAccount, AfterVerify } from './Signup';
import ForgotPasswordView from './ForgotPasswordView';
import validate from './validate';
import { TOKEN_KEY } from '../../Constants';
// import axios from 'axios';
import { withRouter } from 'react-router-dom';
import useAuthStore from '../../Store/AuthStore';
import useMiscStore from '../../Store/MiscStore';
import useBottomNavStore from '../../Store/BottomNavStore';
import useUserStore from '../../Store/UserStore';
import { axiosInstance } from '../../axios_assets';
import {
  Button,
  Modal,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const LOGIN_URL = `${process.env.REACT_APP_LOGIN_KEY}`;
const WISHLIST_URL = `${process.env.REACT_APP_WISHLIST_KEY}`;

/*signup and login forms*/
const Login = ({ history }) => {
  const setAuth = useAuthStore(state => state.setAuth);
  const isAuth = useAuthStore(state => state.isAuth);
  const setUserB2b = useAuthStore(state => state.setUserB2b);
  const { showSignUp, setShowSignUp, formView, setFormView } = useMiscStore(
    state => state
  );
  const { watchList, setWatchList } = useUserStore();
  const { setNavTab } = useBottomNavStore(state => state);
  const { userCreds, setUserCreds } = useUserStore(state => state);
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(null);
  // const [showWatchList, setshowWatchList] = useState(false);
  const [errors, setErrors] = useState({
    wrongPassword: '',
    text: '',
    password: ''
  });
  const [values, setValues] = useState({
    text: '',
    password: ''
  });

  // useEffect(() => {
  //   if (showWatchList) {
  //     axiosInstance
  //       .get(WISHLIST_URL)
  //       .then(response => {
  //         setWatchList(response.data);
  //       })
  //       .catch(error => {
  //         console.error(error);
  //       });
  //   }
  // }, [isAuth]);

  function handlemodelClose() {
    if (formView === 3 || formView === 2 || formView === 1) {
      return;
    }
    // else if(navtab===2){
    //   setValues({
    //     text: '',
    //     password: ''
    //   });
    // }
    else {
      setShowSignUp(false);
      setFormView(0);
      setNavTab(0);
      setValues({
        text: '',
        password: ''
      });
    }
  }
  function handleClose() {
    setShowSignUp(false);
    setFormView(0);
    setValues({
      text: '',
      password: ''
    });
  }
  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      setWrongPassword('');
      axiosInstance
        .post(LOGIN_URL, {
          password: values.password,
          phoneNumber: values.text
        })
        .then(function (response) {
          // console.log(response.data);
          if (response.data) {
            localStorage.setItem(TOKEN_KEY, response.data);
            toast.success('Login successful!');
            setUserB2b(false);
            setWrongPassword('');
            setUserCreds({ ...userCreds, phone: values.text });
            setShowSignUp(false);
            setNavTab(0);
            setAuth(true, () => {
              // history.goBack();
            });
            // setshowWatchList(true);
            setValues({
              text: '',
              password: ''
            });
          }
          //console.log(response.data);
        })
        .catch(function (error) {
          setWrongPassword('Please enter correct details!');
          console.error(error);
        });
    }
  }, [errors]);

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleLoginRequest = e => {
    e.preventDefault();
    setErrors(validate(values));
    setDataIsCorrect(true);
  };
  /*Login from*/
  return (
    <>
      <Modal open={showSignUp} onClose={handlemodelClose}>
        <div className="form" autoComplete="off">
          <IconButton
            className="remove"
            onClick={() => {
              handleClose();
              setNavTab(0);
            }}
          >
            <CloseIcon />
          </IconButton>
          {formView === 0 && (
            <>
              <div className="pop-title">Login</div>
              <div className="create-form">
                <TextField
                  style={{ marginTop: '10px' }}
                  label="Mobile Number"
                  value={values.text}
                  onChange={handleChange}
                  id="email"
                  type="tel"
                  name="text"
                />
                {errors.text && <p className="error">{errors.text}</p>}
                <TextField
                  style={{ marginTop: '10px' }}
                  label="Password"
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  id="password"
                  type="password"
                />
              </div>
              <span>
                <Typography
                  variant="subtitle2"
                  component="span"
                  style={{
                    fontSize: '15px',
                    color: 'blue',
                    cursor: 'pointer',
                    display: 'inline',
                    padding: 0
                  }}
                  onClick={() => setFormView(3)}
                >
                  Forgot Password?
                </Typography>
              </span>
              {errors.password && <p className="error">{errors.password}</p>}
              {wrongPassword && <p className="error">{wrongPassword}</p>}
              <br />
              <Button
                style={{
                  background: '#9c5229',
                  color: 'white',
                  cursor: 'pointer',
                  padding: '3px 9px'
                }}
                variant="contained"
                type="submit"
                onClick={handleLoginRequest}
              >
                Login
              </Button>
              <Typography style={{ fontSize: '15px', color: 'black' }}>
                Don't Have An Account?
              </Typography>
              <span>
                <Button
                  variant="contained"
                  style={{
                    background: '#9c5229',
                    color: 'white',
                    cursor: 'pointer',
                    padding: '3px 9px',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '0 auto'
                  }}
                  onClick={() => setFormView(1)}
                >
                  Register
                </Button>
              </span>
            </>
          )}
          {formView === 1 && <CreateAccount />}
          {formView === 2 && <AfterVerify />}
          {formView === 3 && <ForgotPasswordView />}
        </div>
      </Modal>
    </>
  );
};
export default withRouter(Login);
