import create from 'zustand';
import { persist } from 'zustand/middleware';

const useProductStore = create(set => ({
  products: [],
  setProducts: async products => set({ products: await products }),
  search: '',
  setSearch: search => set({ search }),
  tabs: [
    {
      id: 'RICE',
      toggle: false,
      image: 'images/categories/rice.png',
      alt: 'rice'
    },
    {
      id: 'FRUITS',
      toggle: false,
      image: 'images/categories/fruits.png',
      alt: 'fruits'
    },
    {
      id: 'DRY FRUITS',
      toggle: false,
      image: 'images/categories/dry-fruits.png',
      alt: 'dry-fruits'
    },
    {
      id: 'LEAFYS',
      toggle: false,
      image: 'images/categories/leafys.png',
      alt: 'leafys'
    },
    {
      id: 'VEGGIES',
      toggle: false,
      image: 'images/categories/veggies.png',
      alt: 'veggies'
    }
  ],
  setTabs: input => set({ tabs: input })
}));

export default useProductStore;
