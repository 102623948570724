import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Products from '../Products/Products';
import Cart from '../Cart/Cart';
import UserProfile from '../UserProfile/UserProfile';
import Checkout from '../Checkout/Checkout';
import AboutUs from '../AboutUs/AboutUs';
import Terms from '../Terms/Terms';
import Policy from '../Policy/Policy';
import ShippingPolicy from '../ShippingPolicy/ShippingPolicy';
import ProtectedRoute from '../../Authentication/ProtectedRoute';
import ComingSoon from '../ComingSoon/ComingSoon';

const Routes = () => {
  return (
    <>
      <div>
        <Switch>
          <Route path="/" exact>
            <Products />
          </Route>
          <Route path="/cart" exact>
            <Cart />
          </Route>
          <ProtectedRoute path="/checkout" exact component={Checkout} />
          <Route path="/about" exact>
            <AboutUs />
          </Route>
          <Route path="/terms" exact>
            <Terms />
          </Route>
          <Route path="/policy" exact>
            <Policy />
          </Route>
          <Route path="/shippingPolicy" exact>
            <ShippingPolicy />
          </Route>
          <Route path="/comingsoon" exact>
            <ComingSoon />
          </Route>
          <ProtectedRoute exact path="/user" component={UserProfile} />
        </Switch>
      </div>
    </>
  );
};
export default withRouter(Routes);
