import React from 'react';
import './ComingSoon.css';
import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { withRouter } from 'react-router-dom';

function ComingSoon({ history }) {
  return (
    <div className="cs-root">
      <IconButton onClick={() => history.goBack()} sx={{ margin: '5%' }}>
        <ArrowBack />
      </IconButton>
    </div>
  );
}

export default withRouter(ComingSoon);
