import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Components/Routes/Routes';
import Login from './Components/Signup/Login';
import AddressPopup from './Components/Address/AddressPopup';
import CssBaseline from '@mui/material/CssBaseline';
import useProductStore from './Store/ProductStore';
import useAuthStore from './Store/AuthStore';
import useCartStore from './Store/CartStore';
import useUserStore from './Store/UserStore';
import CircularProgress from '@mui/material/CircularProgress';
import { axiosInstance } from './axios_assets';
import { toast } from 'react-toastify';
import { TOKEN_KEY } from './Constants';
import Loading from './Components/Loading/Loading';
/*constants*/
const PRODUCTS_URL = `${process.env.REACT_APP_PRODUCTS_KEY}`;

const USER_DATA_URL = `${process.env.REACT_APP_USER_DATA_KEY}`;
/*constants*/

const App = () => {
  // console.log(PRODUCTS_URL);
  const { userCreds, setUserData, setWatchList } = useUserStore(state => state);
  const { isAuth, setAuth } = useAuthStore(state => state);
  const { orderInstructions, setOrderInstructions } = useCartStore();
  const [loading, setLoading] = useState(false);
  const setProducts = useProductStore(state => state.setProducts);

  const handleUserProfile = () => {
    axiosInstance
      .post(USER_DATA_URL, {
        phoneNumber: userCreds.phone
      })
      .then(function (response) {
        setUserData(response.data);
        console.log('userData -->' + response.data + '<--');
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          setAuth(false, () => {});
          toast.error('Session Expired!');
          localStorage.removeItem(TOKEN_KEY);
        }
        console.error(error);
      });
  };

  useEffect(() => {
    setLoading(true);

    const getAPI = () => {
      axiosInstance
        .post(PRODUCTS_URL)
        .then(function (response) {
          setProducts(response.data);
          setLoading(false);
          console.log(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    };
    getAPI();
    setOrderInstructions({
      ...orderInstructions,
      deliveryDate: new Date().toISOString().slice(0, 10)
    });
  }, []);

  useEffect(() => {
    isAuth && handleUserProfile();
  }, [isAuth]);

  if (loading) {
    return (
      // <div
      //   style={{
      //     width: '100vw',
      //     height: '100vh',
      //     display: 'flex',
      //     justifyContent: 'center',
      //     alignItems: 'center'
      //   }}
      // >
      //   <CircularProgress size={100} />
      // </div>
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <Router>
        <CssBaseline />
        <Routes />
        <Login />
      </Router>
    </>
  );
};
export default App;
