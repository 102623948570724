import React from 'react';
import SingleProduct from '../Products/SingleProduct';
import useProductStore from '../../Store/ProductStore';
import useUserStore from '../../Store/UserStore';

function WishlistView() {
  /* zustand vars */
  const { products } = useProductStore();
  const { watchList } = useUserStore();
  /* zustand vars */

  return (
    <>
      {products
        .filter(item => watchList.includes(item.productId))
        .map(product => (
          <SingleProduct {...product} key={product.productId} />
        ))}
    </>
  );
}

export default WishlistView;
