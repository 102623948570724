import create from 'zustand';
import { persist } from 'zustand/middleware';
const useCheckoutStore = create(
  persist(
    set => ({
      details: [],
      setDetails: async d => set({ details: await d }),
      fetched: false,
      setFetched: input => set({ fetched: input }),
      orderAddressId: null,
      setOrderAddressId: input => set(state => ({ orderAddressId: input }))
    }),
    {
      name: 'address-store'
    }
  )
);

export default useCheckoutStore;
