import React, { useState } from 'react';
import {
  Button,
  Paper,
  Container,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  IconButton,
  Badge
} from '@mui/material';
import './Header.css';
import { Link, withRouter, useLocation, useHistory } from 'react-router-dom';
import logo from './rer-mandi-logo.png';
import useAuthStore from '../../Store/AuthStore';
import useProductStore from '../../Store/ProductStore';
import useCartStore from '../../Store/CartStore';
import useMiscStore from '../../Store/MiscStore';
import useBottomNavStore from '../../Store/BottomNavStore';
import {
  ShoppingCartOutlined,
  Person,
  ShoppingCart,
  PersonAdd,
  StorefrontRounded,
  PersonOutline
} from '@mui/icons-material';

/*HeaderDesktop*/
const Header = ({ history, searchBar }) => {
  /*zustand variables*/
  const { isAuth, setAuth, setUserB2b } = useAuthStore();
  const { search, setSearch } = useProductStore();
  const { setShowSignUp, setTab, setNavTab } = useMiscStore();
  const { cart } = useCartStore();
  /*zustand variables*/

  /*useState*/
  const [showModel, setShowModel] = React.useState(false);
  /*useState*/

  /*useLocation hook*/
  const location = useLocation();
  /*useLocation hook*/

  return (
    <>
      <div className="navbar">
        <Link
          className="logo"
          to="/"
          onClick={() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            // setNavTab(0);
          }}
        >
          <img src={logo} alt="logo" />
        </Link>
        {searchBar && (
          <div className="search-box">
            <span className="search-icon">
              <i className="fa fa-search"></i>
            </span>
            <input
              type={search}
              onFocus={() => {
                window.scroll({
                  top: window.innerHeight - 130,
                  left: 0,
                  behavior: 'smooth'
                });
              }}
              onChange={e => setSearch(e.target.value)}
              placeholder="Search Items..."
            />
          </div>
        )}
        <div className="cart-profile">
          <Link to="/cart">
            <IconButton style={{ color: '#9c5229', marginRight: '15px' }}>
              <Badge badgeContent={cart.length} color="error">
                <ShoppingCartOutlined
                  className="cart"
                  onClick={() =>
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }
                />
              </Badge>
            </IconButton>
          </Link>
          <IconButton>
            <PersonOutline
              className="profile"
              onClick={() => setShowModel(!showModel)}
            />
            {showModel && (
              <div id="dropdown">
                {isAuth ? (
                  <Container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      '& a': {
                        my: 1
                      }
                    }}
                  >
                    <Link
                      to="/user"
                      onClick={() => {
                        setShowModel(false);
                        setTab(0);
                      }}
                    >
                      Profile
                    </Link>
                    <Link
                      to="/user"
                      onClick={() => {
                        setShowModel(false);
                        setTab(1);
                      }}
                    >
                      Orders
                    </Link>
                    <Link
                      onClick={() => {
                        setShowModel(false);
                        setAuth(false, () => {});
                        setUserB2b(false);
                        localStorage.clear();
                      }}
                    >
                      Logout
                    </Link>
                  </Container>
                ) : (
                  <Link
                    to={() => location.pathname}
                    onClick={() => {
                      setShowSignUp(true);
                      setShowModel(false);
                    }}
                  >
                    Login/Signup
                  </Link>
                )}
              </div>
            )}
          </IconButton>
        </div>
      </div>
      <HeaderMobileView
        history={history}
        searchBar={searchBar}
        setSearch={setSearch}
        search={search}
        cart={cart}
      />
    </>
  );
};
export default withRouter(Header);
/*HeaderDesktop*/

/*HeaderMobileView*/
function HeaderMobileView({ searchBar, search, setSearch, cart }) {
  /* Zustand Variables */
  const { navtab, setNavTab } = useBottomNavStore();
  const { setShowSignUp } = useMiscStore();
  const { isAuth } = useAuthStore();
  /* Zustand Variables */

  const [showBottomNav, setShowBottomNav] = useState(true);
  const history = useHistory();
  return (
    <>
      <nav className="mobile-nav">
        <div className="mobile-top-nav">
          <Link
            to="/"
            className="mobile-logo"
            onClick={() => {
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              setNavTab(0);
            }}
          >
            <img src={logo} alt="logo" />
          </Link>
          {/* <div style={{ display: 'flex' }}> */}
          <input type="checkbox" id="check" />
          {searchBar && (
            <div className="mobile-search-box">
              <input
                placeholder="Search Items..."
                type={search}
                onFocus={() => {
                  window.scroll({
                    top: window.innerHeight - 200,
                    left: 0,
                    behavior: 'smooth'
                  });
                  setShowBottomNav(false);
                }}
                onBlur={() => setShowBottomNav(true)}
                onChange={e => setSearch(e.target.value)}
              />
              <label className="mobile-search-icon" for="check">
                <i className="fa-search fa" style={{ fontSize: '16px' }}></i>
              </label>
            </div>
          )}
          <Button
            variant="outlined"
            style={{
              fontSize: '0.7em',
              padding: '0.1rem 0.5rem',
              border: '1px solid grey',
              marginRight: '1rem',
              whiteSpace: 'nowrap'
            }}
            onClick={() => {
              window.scrollTo(0, document.body.scrollHeight);
            }}
          >
            Get App
          </Button>
          {/* </div> */}
        </div>
        {showBottomNav && (
          <Paper
            sx={{
              position: 'fixed',
              bottom: 0,
              height: '80px',
              py: 2,
              background: 'white',
              zIndex: '1000',
              width: '100%'
            }}
            elevation={3}
          >
            <BottomNavigation
              showLabels
              value={navtab}
              sx={{ px: 1 }}
              onChange={(event, newValue) => {
                setNavTab(newValue);
              }}
            >
              <BottomNavigationAction
                onClick={() => history.push('/')}
                label={<Typography variant="overline">Shop</Typography>}
                icon={<StorefrontRounded />}
              />
              <BottomNavigationAction
                onClick={() => {
                  history.push('/cart');
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
                }}
                label={<Typography variant="overline">Cart</Typography>}
                icon={
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCart />
                  </Badge>
                }
              />
              {isAuth ? (
                <BottomNavigationAction
                  onClick={() => history.push('/user')}
                  label={<Typography variant="overline">Profile</Typography>}
                  icon={<Person />}
                />
              ) : (
                <BottomNavigationAction
                  onClick={() => {
                    setShowSignUp(true);
                    history.push('/');
                  }}
                  label={<Typography variant="overline">Login</Typography>}
                  icon={<PersonAdd />}
                />
              )}
            </BottomNavigation>
          </Paper>
        )}
      </nav>
    </>
  );
}
/*HeaderMobileView*/
