import firebase from 'firebase';
const firebaseConfig = {
  apiKey: 'AIzaSyA9DDa2anAwJRE_-0kn-cxZc91w1ThLlY8',
  authDomain: 'rer-orders.firebaseapp.com',
  projectId: 'rer-orders',
  storageBucket: 'rer-orders.appspot.com',
  messagingSenderId: '459127485523',
  appId: '1:459127485523:web:1bb86e703f0c1b17e0c2a6',
  measurementId: 'G-WCXGBCN9EX'
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export { auth, firebase };

/* 
/ Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA9DDa2anAwJRE_-0kn-cxZc91w1ThLlY8",
  authDomain: "rer-orders.firebaseapp.com",
  projectId: "rer-orders",
  storageBucket: "rer-orders.appspot.com",
  messagingSenderId: "459127485523",
  appId: "1:459127485523:web:1bb86e703f0c1b17e0c2a6",
  measurementId: "G-WCXGBCN9EX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
*/
