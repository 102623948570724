import create from 'zustand';
import { persist } from 'zustand/middleware';

const useAuthStore = create(
  persist(
    set => ({
      userB2b: false,
      setUserB2b: userB2b => set({ userB2b }),
      isAuth: false,
      setAuth: (isAuth, callback) => {
        // console.log('setting auth to ' + isAuth);
        set({ isAuth });
        callback();
      }
    }),
    { name: 'authentication-settings' }
  )
);

export default useAuthStore;
