import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CopyrightIcon from '@mui/icons-material/Copyright';
import useMiscStore from '../../Store/MiscStore';
import appStoreImage from './appstore.png';
import googleStoreImage from './googleplay.png';
const Footer = () => {
  const [operatingSystem, setOs] = React.useState(null);
  // function getOs(){
  //   var userAgent=window.navigator.userAgent
  //   var platform=window.navigator.platform
  //   var iosPlatforms=['iPhone','iPad','iPod']
  //   var windowsPlatforms=['Win32','Win64','Windows','WinCE']
  //   var macosPlatforms=['Macintosh','MacIntel','MacPPC','Mac68k']
  //   var os=null;
  //   if(iosPlatforms.indexOf(platform)!==-1){
  //     os='ios';
  //   }
  //   else if(macosPlatforms.indexOf(platform)!==-1){
  //     os='MacOS';
  //   }
  //   else if(windowsPlatforms.indexOf(platform)!==-1){
  //     os='Windows';
  //   }
  //   else if(/Android/.test(userAgent)){
  //     os='Android';
  //   }
  //   else if(!os && /Linux/.test(platform)){
  //     os='Linux';
  //   }
  //   return os;
  //   }
  function getOs() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }
  var ops = getOs();
  // console.log('os' + getOs());
  return (
    <>
      <div className="footer-header">
        <div className="footer">
          <div className="social-icons">
            <a
              href="https://instagram.com/rootenroute.rer"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/Root-En-Route-108006028331059/"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.youtube.com/channel/UCJqV9PARK5k2AJrPtDKCRwg/"
              target="_blank"
              rel="noreferrer"
            >
              <YouTubeIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/root-enroute"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </a>
          </div>
          <div className="page-links">
            <Link to="/" exact onClick={() => window.scroll(0, 0)}>
              Home
            </Link>
            <Link to="/about" exact onClick={() => window.scroll(0, 0)}>
              About Us
            </Link>
            <Link to="/terms" exact onClick={() => window.scroll(0, 0)}>
              Terms Of Services
            </Link>
            <Link to="/policy" onClick={() => window.scroll(0, 0)}>
              Privacy Policy
            </Link>
            <Link
              to="/ShippingPolicy"
              exact
              onClick={() => window.scroll(0, 0)}
            >
              Shipping Policy
            </Link>
          </div>
        </div>

        {ops === 'iOS' && (
          <div className="getApp">
            {/* <a href="https://apps.apple.com/in/app/root-enroute/id1599500178"> */}
            <Link to="/comingsoon">
              <img className="appStore" src={appStoreImage} alt="apple" />
            </Link>
            {/* </a> */}

            {/* <a href="https://apps.apple.com/in/app/root-enroute/id1599500178"> */}
            <Link to="/comingsoon">
              <img
                className="googleStore"
                src={googleStoreImage}
                alt="google"
              />
            </Link>
            {/* </a> */}
          </div>
        )}

        {ops === 'Android' && (
          <div className="getApp">
            {/* <a href="https://play.google.com/store/apps/details?id=com.rootenroute"> */}
            <Link to="/comingsoon">
              <img className="appStore" src={appStoreImage} alt="apple" />
            </Link>
            {/* </a> */}
            {/* <a href="https://play.google.com/store/apps/details?id=com.rootenroute"> */}
            <Link to="/comingsoon">
              <img
                className="googleStore"
                src={googleStoreImage}
                alt="google"
              />
            </Link>
            {/* </a> */}
          </div>
        )}

        {ops === 'unknown' && (
          <div className="getApp">
            <a href="https://root-enroute.com">
              <img className="appStore" src={appStoreImage} alt="apple" />
            </a>
            <a href="https://root-enroute.com">
              <img
                className="googleStore"
                src={googleStoreImage}
                alt="google"
              />
            </a>
          </div>
        )}
        <div className="rights">
          <span>
            Copyright
            <span>
              <CopyrightIcon style={{ fontSize: '14px', margin: '0 1.5px' }} />
            </span>
            2021 ROOTENROUTE AGRIDIGITAL PRIVATE LIMITED.All rights reserved.
          </span>
        </div>
      </div>
    </>
  );
};
export default Footer;
