import React, { useState, useEffect } from 'react';
import { Button, Box, Modal } from '@mui/material';
import useUserStore from '../../Store/UserStore';
import useCartStore from '../../Store/CartStore';
import usePaymentStore from './../../Store/PaymentStore';
import useCheckoutStore from '../../Store/CheckoutStore';
import useMiscStore from '../../Store/MiscStore';
import './Payment.css';
import { withRouter, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../axios_assets';
import CircularProgress from '@mui/material/CircularProgress';

/* constants */
const PLACE_ORDER_URL = `${process.env.REACT_APP_PLACE_ORDER_KEY}`;
const RAZORPAY_ID_URL = `${process.env.REACT_APP_RAZORPAY_ID_KEY}`;
/* constants */

// styles object
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  p: 4,
  [`@media screen and (max-width:460px)`]: {
    width: 300
  }
};

const Payment = ({ history }) => {
  const userCreds = useUserStore(state => state.userCreds);
  const { cart, setCart, orderInstructions, setOrderInstructions } =
    useCartStore(state => state);
  const setShowPayment = usePaymentStore(state => state.setShowPayment);
  const showPayment = usePaymentStore(state => state.showPayment);
  var paymentId = '';
  const userData = useUserStore(state => state.userData);
  const paymentKey = usePaymentStore(state => state.key);
  const orderAddressId = useCheckoutStore(state => state.orderAddressId);
  const { setPaymentLoading } = useMiscStore();
  const location = useLocation();
  const cartTotal = location.state.params;
  const [rzId, setRzId] = useState('');

  // useEffect(() => {}, [paymentLoading]);

  // if (paymentLoading) {
  //   return (
  //     <div
  //       style={{
  //         width: '100vw',
  //         height: '100vh',
  //         display: 'flex',
  //         backgroundColor: 'red',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         zIndex: 1000
  //       }}
  //     >
  //       <CircularProgress size={100} />
  //     </div>
  //   );
  // }

  /* razorpay script tag inserting */
  function loadScript(src) {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  /* razorpay script tag inserting */

  /* loading order object with details and placing order for cash on delivery */
  const handleCODRequest = () => {
    setShowPayment(false);
    var allItems = [];
    cart.forEach(item => {
      allItems.push({
        itemName: item.title,
        price: item.amount,
        quantity: item.newQuantity,
        productId: item.itemId,
        units: `${item.quantity} ${item.units}`
      });
    });
    axiosInstance
      .post(PLACE_ORDER_URL, {
        b2bOrders: {
          b2bUser: {
            phoneNumber: userCreds.phone
          },
          total: cartTotal,
          paymentId: 'COD',
          orderAddressId: orderAddressId,

          timeSlot: orderInstructions.timeSlot,
          deliveryDate: orderInstructions.deliveryDate
        },
        b2bOrderInstructions: {
          instructions: orderInstructions.instructions
        },
        allB2bItems: allItems
      })
      .then(function (response) {
        if (response.status === 200) {
          clearCart();
        } else {
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  /* loading order object with details and placing order for cash on delivery */

  /* clear cart after order has been placed */
  const clearCart = () => {
    toast.success('Your order has been placed successfully!');
    setCart([]);
    setOrderInstructions({
      instructions: '',
      deliveryDate: new Date().toISOString().slice(0, 10),
      timeSlot: '6am-12pm'
    });
    history.push('/');
  };
  /* clear cart after order has been placed */

  /* opening razorpay portal and loading order object with details and placing order for online payment */
  async function displayRazorpay() {
    setShowPayment(false);
    setPaymentLoading(true);
    var allItems = [];
    cart.forEach(item => {
      allItems.push({
        itemName: item.title,
        price: item.amount,
        quantity: item.newQuantity,
        productId: item.itemId,
        units: `${item.quantity} ${item.units}`
      });
    });
    axiosInstance
      .get(`${RAZORPAY_ID_URL}/${cartTotal}/${orderAddressId}`)
      .then(response => {
        console.log('first: ', response);
        setRzId(response.data);
        setPaymentLoading(false);
        return response;
      })
      .then(function (response) {
        console.log('second: ', response);
        if (response.data) {
          const options = {
            key: paymentKey.key,
            currency: 'INR',
            amount: cartTotal * 100,
            name: 'Root Enroute',
            description: 'From the field to your table!!',
            image: null,
            order_id: rzId,
            handler: function (response) {
              // paymentId = response.razorpay_payment_id;
              if (response.razorpay_payment_id) {
                axiosInstance
                  .post(PLACE_ORDER_URL, {
                    b2bOrders: {
                      b2bUser: {
                        phoneNumber: userCreds.phone
                      },
                      total: cartTotal,
                      paymentId: 'Online',
                      orderAddressId: orderAddressId,
                      timeSlot: orderInstructions.timeSlot,
                      deliveryDate: orderInstructions.deliveryDate
                    },
                    b2bOrderInstructions: {
                      instructions: orderInstructions.instructions
                    },
                    allB2bItems: allItems,
                    razorpayOrderId: rzId
                  })
                  .then(response => clearCart());
              }
            },
            prefill: {
              name: userCreds.firstname + ' ' + userCreds.lastName,
              email:
                userData.email === ''
                  ? 'support@root-enroute.com'
                  : userData.email,
              contact: userCreds.phone
            }
          };
          console.log(options);
          setPaymentLoading(false);
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
  }
  /* opening razorpay portal and loading order object with details and placing order for online payment */

  return (
    <>
      <div className="blur">
        <Modal
          open={showPayment}
          onClose={() => setShowPayment(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="box" sx={style}>
            <h2 style={{ color: 'black', fontSize: '14px' }}>
              Choose Payment Method
            </h2>
            <div>
              <Button
                style={{ margin: '10px', background: '#9c5229' }}
                variant="contained"
                onClick={() => {
                  displayRazorpay();
                }}
              >
                Pay Now
              </Button>
            </div>
            <Button
              variant="outlined"
              style={{ margin: '10px' }}
              onClick={() => {
                handleCODRequest();
              }}
            >
              Cash On Delivery
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default withRouter(Payment);
