import create from 'zustand';

const useMiscStore = create(set => ({
  tab: 0,
  setTab: tab => set({ tab }),
  showSignUp: false,
  setShowSignUp: showSignUp => set({ showSignUp }),
  formView: 0,
  setFormView: formView => set({ formView }),
  loginModal: false,
  setLoginModal: loginModal => set({ loginModal }),
  mobileNumber: '',
  setMobileNumber: mobileNumber => set({ mobileNumber }),
  pass: false,
  setPass: pass => set({ pass }),
  addAddress: true,
  setAddAddress: addAddress => set({ addAddress }),
  paymentLoading: false,
  setPaymentLoading: paymentLoading => set({ paymentLoading }),
  /* boolean for address view */
  addressView: 0,
  setAddressView: addressView => set({ addressView })
}));

export default useMiscStore;
