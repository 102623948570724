import axios from 'axios';

const accessToken = 'testing-token';

export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  // headers: {
  //   Authorization: `Bearer ${accessToken}`
  // }
});
