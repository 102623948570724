import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuthStore from '../Store/AuthStore';

function ProtectedRoute({ component: Component, ...rest }) {
  const isAuth = useAuthStore(state => state.isAuth);
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuth) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
}

export default ProtectedRoute;
