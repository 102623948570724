import create from 'zustand';
import { persist } from 'zustand/middleware';

const usePaymentStore = create(
  persist(
    set => ({
      key: '',
      setKey: key => set({ key }),
      showPayment: false,
      setShowPayment: input => set({ showPayment: input })
      // cartTotal: 0,
      // setCartTotal: cartTotal => set({ cartTotal })
    }),
    {
      name: 'null'
      // getStorage: () => sessionStorage
    }
  )
);

export default usePaymentStore;
