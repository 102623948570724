import React, { useEffect, useState } from 'react';
import './Signup.css';
import { Link, useHistory } from 'react-router-dom';
import validatePassword from './ValidatePassword';
import { firebase, auth } from './Firebase';
// import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import useAuthStore from '../../Store/AuthStore';
import useMiscStore from '../../Store/MiscStore';
import useUserStore from '../../Store/UserStore';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../axios_assets';
import { TOKEN_KEY } from '../../Constants';
const CHECK_EXISTING_URL = `${process.env.REACT_APP_CHECK_EXISTING_KEY}`;

const SIGNUP_URL = `${process.env.REACT_APP_SIGNUP_KEY}`;

/*SignupForm and RegisterForm*/
export const CreateAccount = () => {
  const { setFormView } = useMiscStore(state => state);
  const [error, setError] = useState(false);
  const [isExisting, setIsExisting] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState();
  const { setPass, mobileNumber, setMobileNumber } = useMiscStore(
    state => state
  );
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState('');
  const countryCode = '+91';
  function validatePhoneNumber(number) {
    var re = /^\d{10}$/im;
    return re.test(number);
  }
  var isRegistered = false;
  const signin = () => {
    //console.log('Inside signin ...');
    if (isRegistered) {
      //console.log('inside if...', isRegistered);
      return;
    }
    //console.log('yes i enetered', isExisting, isRegistered);
    if (!validatePhoneNumber(mobileNumber)) {
      setError(true);
      setErrorMessage('Invalid Mobile Number');
      return;
    }
    setError(false);
    setErrorMessage('');
    let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    auth
      .signInWithPhoneNumber(countryCode + mobileNumber, verify)
      .then(result => {
        setfinal(result);
        setshow(true);
      })
      .catch(err => {
        alert(err);
        window.location.reload();
      });
  };
  const handleCheckUserExistsRequest = async () => {
    try {
      const response = await axiosInstance.post(CHECK_EXISTING_URL, {
        phoneNumber: mobileNumber
      });
      //console.log(response.data);
      //console.log('response received...');
      if (response.data === true) {
        isRegistered = true;
        setIsExisting('User already exists');
        //console.log('user registered: ', response.data);
      }
      signin();
      //console.log('signin executed');
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  // Validate OTP
  const ValidateOtp = () => {
    //console.log('here   ', otp);
    if (otp === '' || final === null) {
      setError(true);
      setErrorMessage('Invalid OTP');
      return;
    }
    setError(false);
    setFormView(2);
    setErrorMessage('');
    final
      .confirm(otp)
      .then(result => {
        setPass(true);
      })
      .catch(err => {
        setError(true);
        setErrorMessage('Invalid OTP');
      });
  };
  return (
    <div className="login-pop">
      <div className="pop-title">Create Account</div>
      <div style={{ display: !show ? 'block' : 'none' }}>
        <br />
        <TextField
          label="Mobile Number"
          onChange={e => {
            setMobileNumber(e.target.value);
          }}
          sx={{ width: '100%', my: '2%' }}
          type="tel"
          value={mobileNumber}
          name="mobileNumber"
        />
        <br />
        <div id="recaptcha-container"></div>
        {error && <p className="error">{errorMessage}</p>}
        {isExisting !== '' && <p className="error">{isExisting}</p>}
        <Button
          variant="contained"
          sx={{ my: '2%', mx: 'auto' }}
          onClick={handleCheckUserExistsRequest}
        >
          Send OTP
        </Button>
      </div>
      <div style={{ display: show ? 'block' : 'none' }}>
        <TextField
          type="text"
          placeholder={'Enter your OTP'}
          sx={{ width: '100%', my: '2%' }}
          onChange={e => {
            setOtp(e.target.value);
          }}
        />
        <br />
        <Button
          variant="contained"
          sx={{ my: '2%', mx: 'auto' }}
          onClick={ValidateOtp}
        >
          Verify
        </Button>
        {error ? <p className="error">{errorMessage}</p> : <div></div>}
      </div>
      <div>Existing user?</div>
      <Button
        variant="contained"
        sx={{ my: '2%', mx: 'auto' }}
        onClick={() => setFormView(0)}
      >
        Login
      </Button>
    </div>
  );
};

export const AfterVerify = () => {
  const { setShowSignUp, setFormView } = useMiscStore(state => state);
  let history = useHistory();
  const { pass, mobileNumber } = useMiscStore(state => state);
  const { setAuth } = useAuthStore();
  const { setUserCreds, userCreds } = useUserStore();
  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    enterpriseName: '',
    gstNumber: '',
    requirementFrequency: 'Daily',
    businessType: ''
  });
  const [errors, setErrors] = useState({});
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const theme = useTheme();

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      axiosInstance
        .post(SIGNUP_URL, {
          firstName: values.firstname,
          lastName: values.lastname,
          email: values?.email,
          customerType: 'B2B',
          password: values.password,
          phoneNumber: mobileNumber,
          enterpriseName: values.enterpriseName,
          gstNumber: values.gstNumber,
          requirementFrequency: values.requirementFrequency,
          businessType: values.businessType
        })
        .then(function (response) {
          // console.log(response.data);
          if (response.data) {
            // console.log(response.data);
            localStorage.setItem(TOKEN_KEY, response.data);
            toast.success('Login successful!');
            setDataIsCorrect(true);
            setUserCreds({
              ...userCreds,
              phone: mobileNumber,
              email: values?.email,
              firstName: values.firstName,
              lastName: values.lastName
            });
            setAuth(true, () => {
              // history.push('/');
            });
          }
          //console.log(response);
          setValues({
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            enterpriseName: '',
            gstNumber: '',
            requirementFrequency: 'Daily',
            businessType: ''
          });
        })
        .catch(function (error) {
          console.error(error);
        });
      // }
      setShowSignUp(false);
      setFormView(0);
    }
  }, [errors]);

  const handleRegisterRequest = e => {
    e.preventDefault();
    setErrors(validatePassword(values));
    setDataIsCorrect(true);
  };
  // if (!pass) return null;
  return (
    <div className="login-pop">
      <div className="pop-title">User Registeration</div>
      <>
        <div className="create-form" autoComplete="off">
          <section className="groupedText">
            <TextField
              type="text"
              label="First Name"
              name="firstname"
              size="small"
              helperText={
                errors.firstname && <p className="error">{errors.firstname}</p>
              }
              value={values.firstname}
              onChange={handleChange}
            />
            <TextField
              type="text"
              label="Last Name"
              name="lastname"
              size="small"
              helperText={
                errors.lastname && <p className="error">{errors.lastname}</p>
              }
              value={values.lastname}
              onChange={handleChange}
            />
          </section>
          <TextField
            type="email"
            label="Email(Optional)"
            name="email"
            size="small"
            className="fields"
            helperText={errors.email && <p className="error">{errors.email}</p>}
            onChange={handleChange}
            value={values.email}
          />
          <TextField
            type="password"
            label="Password"
            name="password"
            className="fields"
            size="small"
            helperText={
              errors.password && <p className="error">{errors.password}</p>
            }
            value={values.password}
            onChange={handleChange}
          />
          <TextField
            type="text"
            label="Enterprise Name"
            name="enterpriseName"
            className="fields"
            size="small"
            helperText={
              errors.enterpriseName && (
                <p className="error">{errors.enterpriseName}</p>
              )
            }
            value={values.enterpriseName}
            onChange={handleChange}
          />
          <TextField
            type="text"
            label="Business Type"
            name="businessType"
            className="fields"
            size="small"
            placeholder="Like Restaurant, Hotel, etc."
            helperText={
              errors.businessType && (
                <p className="error">{errors.businessType}</p>
              )
            }
            value={values.businessType}
            onChange={handleChange}
          />
          <section className="groupedText">
            <TextField
              type="text"
              label="GST No."
              name="gstNumber"
              size="small"
              helperText={
                errors.gstNumber && <p className="error">{errors.gstNumber}</p>
              }
              value={values.gstNumber}
              onChange={handleChange}
            />
            <FormControl sx={{ width: '50%' }} size="small">
              <InputLabel>Requirement Frequency</InputLabel>
              <Select
                name="requirementFrequency"
                value={values.requirementFrequency}
                label="Requirement Freq"
                onChange={handleChange}
              >
                <MenuItem value="Daily">Daily</MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
                <MenuItem value="Monthly">Monthly</MenuItem>
              </Select>
            </FormControl>
          </section>
        </div>
      </>
      <div>
        <div className="check-terms">
          <input
            type="checkbox"
            id="terms"
            onClick={e => {
              handleChange(e);
              setAgreed(!agreed);
            }}
          />
          <label htmlFor="terms">
            I agree to the
            <Link
              to={`/terms`}
              target="_blank"
              style={{
                textDecoration: 'none',
                listStyle: 'none',
                color: 'blue',
                margin: '0 3px'
              }}
              exact
              onClick={() => window.scroll(0, 0)}
            >
              Terms of Service
            </Link>
            and
            <Link
              to="/policy"
              target="_blank"
              exact
              style={{
                textDecoration: 'none',
                listStyle: 'none',
                color: 'blue',
                margin: '0 3px'
              }}
              onClick={() => window.scroll(0, 0)}
            >
              Privacy Policy
            </Link>
          </label>
        </div>
        {errors.agreed && <p className="error">{errors.agreed}</p>}
      </div>
      <br />
      <Button
        variant="contained"
        sx={{ my: theme.spacing(2) }}
        onClick={handleRegisterRequest}
        className="register"
        disabled={!agreed}
      >
        Register & Login
      </Button>
    </div>
  );
};
