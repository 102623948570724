import React, { useCallback, useEffect, useMemo } from 'react';
import useProductStore from '../../Store/ProductStore';
import useUserStore from '../../Store/UserStore';
import useAuthStore from '../../Store/AuthStore';
import Category from './Category/Category';
import Slideshow from './Slides/Slideshow';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import SingleProduct from './SingleProduct';
import './Products.css';
import { axiosInstance } from '../../axios_assets';
import { ArrowUpward } from '@mui/icons-material';

const WISHLIST_URL = `${process.env.REACT_APP_WISHLIST_KEY}`;

/*product*/
const Products = () => {
  /*zustand variables*/
  const { products, tabs, search } = useProductStore(state => state);
  const { isAuth } = useAuthStore();
  const { setWatchList } = useUserStore();
  /*zustand varaibles*/

  /*filteredTabs*/
  const filteredTabs = tabs
    .filter(tab => {
      return tab.toggle;
    })
    .map(tab => {
      return tab.id;
    });
  //console.log(filteredTabs);
  /*filteredTabs*/

  /*FilterByCategoryAndSearchBar*/
  var newArray = [];

  if (filteredTabs.length === 0) {
    const filterBySearch = products.filter(product => {
      if (product.title.toLowerCase().includes(search.toLowerCase())) {
        return product;
      }
    });
    newArray = filterBySearch;
  }
  if (filteredTabs.length > 0) {
    var filterByCategory = filteredTabs.reduce((acc, tab) => {
      const filteredProduct = products.filter(product => {
        if (product.category.toLowerCase() === tab.toLowerCase()) {
          return product;
        }
      });
      acc = acc.concat(filteredProduct);
      return acc;
    }, []);
    newArray = filterByCategory;
  }
  if (filteredTabs.length > 0 && search) {
    newArray = filterByCategory.filter(p => {
      if (p.title.toLowerCase().includes(search.toLowerCase())) {
        return p;
      }
    });
  }
  /*FilterByCategoryAndSearchBar*/

  const getWatchlist = () => {
    axiosInstance
      .get(WISHLIST_URL)
      .then(response => {
        setWatchList(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (isAuth) {
      getWatchlist();
    } else {
      setWatchList([]);
    }
  }, [isAuth]);

  return (
    <>
      {/* <Banner /> */}
      <Header searchBar={true} />
      <div className="products-outer-box">
        <Slideshow />
        <Category />
        <div className="products">
          {newArray
            .filter(pduct => pduct.product_status !== 'NA')
            .map(product => (
              <SingleProduct {...product} key={product.productId} />
            ))}
        </div>
      </div>
      <span className="scroll-top" onClick={() => window.scrollTo(0, 0)}>
        <ArrowUpward /> Top
      </span>
      <Footer />
    </>
  );
};
export default Products;
/*product*/
