import create from 'zustand';
import { persist } from 'zustand/middleware';
const useBottomNavStore = create(
  persist(set => ({
    navtab: 0,
    setNavTab: navtab => set({ navtab })
  }))
);

export default useBottomNavStore;
