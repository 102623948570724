import React, { useEffect } from 'react';
import '../Products/Products.css';
import MobileView from './UserProfileMb';
import OrdersView from './OrdersView';
import AddressView from './AddressView';
import WishlistView from './WishlistView';
import useAuthStore from '../../Store/AuthStore';
import useUserStore from '../../Store/UserStore';
import useCheckoutStore from '../../Store/CheckoutStore';
import useMiscStore from '../../Store/MiscStore';
import useBottomNavStore from '../../Store/BottomNavStore';
import { Link, withRouter } from 'react-router-dom';
import {
  Button,
  Paper,
  Container,
  Typography,
  Tabs,
  Tab,
  Box
} from '@mui/material';
import {
  AccountCircleRounded,
  ShoppingBasketRounded,
  LocationOn,
  FavoriteRounded,
  ArrowBack,
  ArrowForward
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { styled, useTheme } from '@mui/material/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { axiosInstance } from '../../axios_assets';
import { TOKEN_KEY } from '../../Constants';
import { toast } from 'react-toastify';

/* constants */
const USER_DATA_URL = `${process.env.REACT_APP_USER_DATA_KEY}`;
const ADDRESS_URL = `${process.env.REACT_APP_ALL_ADDRESS_KEY}`;
const ALL_ORDERS_URL = `${process.env.REACT_APP_ALL_ORDERS_KEY}`;
const WISHLIST_URL = `${process.env.REACT_APP_WISHLIST_KEY}`;
/* constants */

// user profile root paper
const RootPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: '70%',
  backgroundColor: 'var(--bodyBg)',
  position: 'relative',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  '&::before': {
    content: '""',
    backgroundSize: 'cover',
    background: `url("bgg1.png")`,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.06
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

/* mui classes definition */
const useStyles = makeStyles(theme => ({
  detailsBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '120vw',
    height: '98vh',
    padding: '7% 2%',
    color: theme.palette.primary.main,
    textAlign: 'left',
    marginLeft: theme.spacing(3),
    '& button': {
      width: '100px',
      margin: theme.spacing(3)
    },
    '& h4': {
      fontSize: 'clamp(1rem,2vw,1.5rem)',
      textAlign: 'left',
      color: 'black'
    },
    '& h4:first-child': {
      fontSize: 'clamp(1rem,2.5vw,2rem)'
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%',
      height: '90%'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  profIllut: {
    width: '120%',
    marginLeft: '-20%',
    zIndex: 1000,
    pointerEvents: 'none'
  },
  tabs: {
    width: '15%',
    '& div': {
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.down('sm')]: {
      width: '20%',
      padding: 0,
      margin: 0,
      height: '100vh'
    },
    [theme.breakpoints.up('lg')]: {
      width: '20vw'
    }
  },
  oneTab: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      width: '30px'
    }
  },
  tabTitle: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  rootBox: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    height: '92vh',
    width: '100%',
    borderRadius: '5px',
    position: 'relative',
    right: 0,
    margin: `0 1vw`,
    [theme.breakpoints.down('lg')]: {
      height: '95vh'
    },
    [theme.breakpoints.down('md')]: {
      height: '90vh',
      margin: `0 ${theme.spacing(3)}`,
      padding: `${theme.spacing(3)} 0`
    }
  },
  scrollContainer: {
    width: '100vw',
    height: '85vh',
    padding: '3%',
    boxShadow: '0 4px 8px 0 hsl(0, 0%, 90%)',
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      width: '70vw'
    },
    [theme.breakpoints.down('md')]: {
      width: '80vw'
    },
    [theme.breakpoints.up('lg')]: {
      width: '70vw'
    },
    '@media (min-width: 1500px)': {
      width: '30vw'
    }
  },
  editForm: {
    margin: '10%',
    '& input': {
      minWidth: '400px'
    },
    '& div': {
      marginBottom: '5%'
    }
  },
  image: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100px'
    }
  },
  illustrationBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '45vh',
    '@media(max-width:800px)': {
      height: '60vh'
    },
    [theme.breakpoints.down('sm')]: {
      height: '40vh'
    }
  },
  illustration: {
    width: '30em',
    opacity: '0.8',
    userSelect: 'none',
    '@media(max-width:800px)': {
      width: '55vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '60vw'
    },
    '@media(max-width: 500px)': {
      width: '75vw'
    },
    '@media(max-width: 400px)': {
      width: '85vw'
    }
  },
  shopNow: {
    textDecoration: 'none',
    listStyle: 'none',
    transform: 'translateY(-100%)'
  }
}));

/* custom tabpanel component for rendering active tabs */
function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
/* prop types definition for custom tabpanel */
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

/* User Profile Desktop View */
function UserProfile({ history }) {
  /* zustand vars */
  const { isAuth, setAuth, setUserB2b } = useAuthStore();
  const {
    userCreds,
    allOrders,
    setAllOrders,
    userData,
    setUserData,
    watchList,
    setWatchList
  } = useUserStore();
  const { setDetails } = useCheckoutStore();
  const { tab, setTab } = useMiscStore();
  const { setNavTab } = useBottomNavStore();
  /* zustand vars */

  const classes = useStyles();

  const theme = useTheme();

  // set nav tab to profile tab
  useEffect(() => {
    setNavTab(2);
  }, []);

  /* toggling tabs change */
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  /* To fetch all user addresses */
  const getAllAddress = () => {
    axiosInstance
      .get(ADDRESS_URL)
      .then(function (response) {
        setDetails(response.data);
        //console.log('address data ' + response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  /* To fetch user data */
  const handleUserProfile = () => {
    axiosInstance
      .post(USER_DATA_URL, {
        phoneNumber: userCreds.phone
      })
      .then(function (response) {
        setUserData(response.data);
        // console.log('userData -->' + response.data + '<--');
      })
      .catch(function (error) {
        /* when user is unauthorized */
        if (error.response.status === 401) {
          setAuth(false, () => {});
          toast.error('Session Expired!');
          localStorage.removeItem(TOKEN_KEY);
        }
        console.error(error);
      });
  };

  /* To fetch all user orders */
  const handleGetAllOrders = () => {
    axiosInstance
      .get(ALL_ORDERS_URL)
      .then(function (response) {
        setAllOrders(response.data.reverse());
        // console.log('orders' + JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  /* To fetch all user wishlist items */
  const getWishlist = () => {
    axiosInstance
      .get(WISHLIST_URL)
      .then(response => {
        setWatchList(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  /* calling all fetch calls on update of orders and on login */
  useEffect(() => {
    handleUserProfile();
    handleGetAllOrders();
    getAllAddress();
    getWishlist();
  }, []);

  return (
    <>
      <RootPaper elevation={4}>
        <Box className={classes.bigBox}>
          <div
            style={{
              margin: '0 5vw',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Link to="/">
              <Button
                startIcon={<ArrowBack />}
                sx={{
                  color: theme.palette.secondary.main,
                  border: 'none',
                  textDecoration: 'none',
                  listStyle: 'none',
                  padding: '5px',
                  margin: '2%',
                  fontSize: '18px',
                  whiteSpace: 'nowrap'
                }}
              >
                Back to Shop
              </Button>
            </Link>
            <Link to="/cart">
              <Button
                endIcon={<ArrowForward />}
                sx={{
                  color: theme.palette.secondary.main,
                  border: 'none',
                  textDecoration: 'none',
                  listStyle: 'none',
                  padding: '5px',
                  margin: '2%',
                  fontSize: '18px',
                  whiteSpace: 'nowrap'
                }}
              >
                Go to Cart
              </Button>
            </Link>
          </div>
          <Container className={classes.rootBox}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tab}
              onChange={handleChange}
              sx={{ borderRight: 1, borderColor: 'divider' }}
              className={classes.tabs}
            >
              <Tab
                className={classes.oneTab}
                label={
                  <Typography variant="button" className={classes.tabTitle}>
                    Account
                  </Typography>
                }
                id="vertical-tab-0"
                aria-controls="vertical-tabpanel-0"
                icon={<AccountCircleRounded />}
              ></Tab>
              <Tab
                className={classes.oneTab}
                label={
                  <Typography variant="button" className={classes.tabTitle}>
                    Orders
                  </Typography>
                }
                icon={<ShoppingBasketRounded />}
              />
              <Tab
                className={classes.oneTab}
                label={
                  <Typography variant="button" className={classes.tabTitle}>
                    Address
                  </Typography>
                }
                icon={<LocationOn />}
              />
              <Tab
                className={classes.oneTab}
                label={
                  <Typography variant="button" className={classes.tabTitle}>
                    Wishlist
                  </Typography>
                }
                icon={<FavoriteRounded />}
              />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Container className={classes.scrollContainer}>
                <div style={{ display: 'flex', overflowY: 'hidden' }}>
                  {userData && (
                    <Box className={classes.detailsBox}>
                      Username:
                      <Typography variant="h5" component="h4" gutterBottom>
                        {userData.firstName + ' ' + userData.lastName}
                      </Typography>
                      {userData.email && (
                        <>
                          Email:
                          <Typography
                            variant="body1"
                            component="h4"
                            gutterBottom
                          >
                            {userData.email}
                          </Typography>
                        </>
                      )}
                      Phone:
                      <Typography variant="body1" component="h4" gutterBottom>
                        {'+91' + userData.phoneNumber}
                      </Typography>
                      Enterprise Name:
                      <Typography variant="body1" component="h4" gutterBottom>
                        {userData.enterpriseName}
                      </Typography>
                      {userData.gstNumber && (
                        <>
                          GST No.:
                          <Typography
                            variant="body1"
                            component="h4"
                            gutterBottom
                          >
                            {userData.gstNumber}
                          </Typography>
                        </>
                      )}
                      Requirement Frequency:
                      <Typography variant="body1" component="h4" gutterBottom>
                        {userData.requirementFrequency}
                      </Typography>
                      <Button
                        variant="outlined"
                        gutter
                        onClick={() => {
                          setAuth(false, () => {
                            history.push('/');
                          });
                          setUserB2b(false);
                          setNavTab(0);
                          localStorage.clear();
                        }}
                      >
                        <Typography variant="caption" sx={{ color: 'black' }}>
                          Logout
                        </Typography>
                      </Button>
                    </Box>
                  )}
                  <Box>
                    <img
                      className={classes.profIllut}
                      src="profile-illustration.gif"
                      alt="profile-illustration"
                    />
                  </Box>
                </div>
              </Container>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Container className={classes.scrollContainer}>
                <Scrollbars
                  style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }}
                >
                  {allOrders.length > 0 ? (
                    <OrdersView />
                  ) : (
                    <div
                      className={classes.illustrationBox}
                      style={{ paddingTop: '20%' }}
                    >
                      <img
                        src="illustration\bgs order.png"
                        alt="orders illustration"
                        className={classes.illustration}
                      />
                      <Typography
                        variant="body1"
                        sx={{ transform: 'translateY(-400%)' }}
                      >
                        No Orders.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ transform: 'translateY(-395%)' }}
                      >
                        Looks like you haven't placed any order yet!
                      </Typography>
                      <Link to="/" className={classes.shopNow}>
                        <Button
                          variant="contained"
                          style={{
                            padding: '10px'
                          }}
                          onClick={() => setNavTab(0)}
                        >
                          Shop-Now
                        </Button>
                      </Link>
                    </div>
                  )}
                </Scrollbars>
              </Container>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Container className={classes.scrollContainer}>
                <Scrollbars
                  style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }}
                >
                  <AddressView />
                </Scrollbars>
              </Container>
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <Container className={classes.scrollContainer}>
                <Scrollbars
                  style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }}
                >
                  <div className="products">
                    {watchList.length < 1 ? (
                      <>
                        <div className={classes.illustrationBox}>
                          <img
                            src="illustration\bgswatchlist.png"
                            alt="address illustration"
                            className={classes.illustration}
                            style={{ paddingTop: '70%' }}
                          />
                          <Typography
                            variant="body1"
                            sx={{ transform: 'translateY(-200%)' }}
                          >
                            Sorry! Your wishlist is empty.
                          </Typography>
                          <Link
                            to="/"
                            className={classes.shopNow}
                            style={{ marginTop: '10%' }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                padding: '10px'
                              }}
                              onClick={() => setNavTab(0)}
                            >
                              Wishlist Now
                            </Button>
                          </Link>
                          {/* <Typography
                          variant="body1"
                          sx={{ transform: 'translateY(-345%)' }}
                        >
                          Add a delivery address.
                        </Typography> */}
                        </div>
                      </>
                    ) : (
                      <WishlistView />
                    )}
                  </div>
                </Scrollbars>
              </Container>
            </TabPanel>
          </Container>
        </Box>
      </RootPaper>
      <MobileView />
    </>
  );
}
/* User Profile Desktop View */

export default withRouter(UserProfile);
