import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
  Typography,
  Card,
  Paper,
  Button,
  Box,
  Modal,
  CardHeader,
  CardContent,
  Collapse,
  Avatar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {
  ExpandMore,
  AccessTimeRounded,
  DoneRounded,
  CancelOutlined
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { axiosInstance } from '../../axios_assets';
import useProductStore from '../../Store/ProductStore';
import useUserStore from '../../Store/UserStore';
import useCartStore from '../../Store/CartStore';
import { toast } from 'react-toastify';

/* constants */
const CANCEL_ORDER_URL = `${process.env.REACT_APP_CANCEL_ORDER_KEY}`;
const PRODUCTS_URL = `${process.env.REACT_APP_PRODUCTS_KEY}`;
/* constants */

const useStyles = makeStyles(theme => ({
  tHead: {
    backgroundColor: theme.palette.secondary.main,
    '& p': {
      color: 'white'
    }
  },
  repeatOrderButton: {
    margin: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  repeatIcon: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  expand: {
    margin: '5px'
  },
  orderText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
}));

const Expand = styled(props => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const StatusAvatar = ({ orderStatus }) => {
  if (orderStatus === 'Delivered') return <DoneRounded />;
  else if (orderStatus === 'Cancel') return <CancelOutlined />;
  else return <AccessTimeRounded />;
};

function SingleOrder({
  total,
  orderedOn,
  items,
  orderStatus,
  invoiceId,
  history
}) {
  const { products, setProducts } = useProductStore();
  const { setCart, addToCart } = useCartStore();

  const [expanded, setExpanded] = useState(false);

  const classes = useStyles();

  const theme = useTheme();

  const getCancelTime = useCallback(() => {
    const year = parseInt(orderedOn.slice(0, 4));
    const month = parseInt(orderedOn.slice(5, 7)) - 1;
    const day = parseInt(orderedOn.slice(8, 10));
    const hours = parseInt(orderedOn.slice(11, 13)) + 5;
    const minutes = parseInt(orderedOn.slice(14, 16)) + 30;
    const seconds = parseInt(orderedOn.slice(17, 19));

    const d = new Date(year, month, day, hours, minutes, seconds, 0);
    // console.log('backend time' + d);
    // console.log('Before change: ' + d);
    d.setHours(d.getHours() + 2);
    // console.log('After change: ' + d);
    let now = Date.now();
    // console.log('Now Time: ' + new Date(now));
    return d.valueOf() >= now;
  }, [orderedOn]);

  const [cancelTime, setCancelTime] = useState(getCancelTime());

  //remove modal open boolean
  const [repeat, setRepeat] = React.useState(false);
  const [checkout, setCheckout] = useState();

  const [OSItems, setOSItems] = useState([]);

  useEffect(() => {
    checkout && CROS();
  }, [products]);

  useEffect(() => {
    // console.log(' useEffect OSItems ', OSItems);
    checkout && setRepeat(true);
  }, [OSItems]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /* Check and remove OS or NA products from the cart */
  const CROS = () => {
    console.log('CROS called');
    var OSproducts = items.filter(item => {
      const tempItem = products.find(
        product => product.productId === item.productId
      );
      return tempItem.product_status !== 'AT';
    });
    if (OSproducts.length > 0) {
      console.log('OSproducts ', OSproducts);
      setOSItems(OSproducts);
      // console.log('OSItems ', OSItems);
      // setRepeat(true);
    } else {
      repeatOrder();
    }
  };
  /* Check and remove OS or NA products from the cart */

  /* fetch products */
  const getProducts = () => {
    axiosInstance
      .post(PRODUCTS_URL)
      .then(function (response) {
        // console.log('response', response.data);
        setProducts(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  /* fetch products */

  /*repeatOrder*/
  const repeatOrder = () => {
    setCart([]);
    items.map(item => {
      const filteredProduct = products.find(product => {
        if (product.productId === item.productId) {
          return product;
        }
      });
      console.log('order item: ', item);
      console.log('filtered: ', filteredProduct);
      addToCart({
        itemId: item.productId,
        title: filteredProduct.title,
        amount: filteredProduct.amount,
        quantity: filteredProduct.quantity,
        image: filteredProduct.image,
        newQuantity: item.quantity,
        units: filteredProduct.units
      });
    });
    history.push('/cart');
    toast.success('items added to cart');
  };
  /*repeatOrder*/

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            <Avatar
              sx={{
                bgcolor: theme.palette.secondary.main,
                [theme.breakpoints.down('sm')]: {
                  fontSize: '5px'
                }
              }}
            >
              <StatusAvatar orderStatus={orderStatus} />
            </Avatar>
          }
          action={
            <div className={classes.actionBar}>
              {cancelTime && orderStatus !== 'Cancel' ? (
                <button
                  size="small"
                  variant="outlined"
                  onClick={() => setOpen(true)}
                  style={{
                    outline: '1px solid #9c5229',
                    color: '#9c5229',
                    backgroundColor: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    marginRight: '2%',
                    padding: '5%',
                    whiteSpace: 'nowrap',
                    cursor: 'pointer'
                  }}
                >
                  Cancel Order
                </button>
              ) : null}
              {(orderStatus === 'Cancel' ||
                orderStatus === 'Not Delivered' ||
                orderStatus === 'Delivered') && (
                <button
                  style={{
                    outline: '1px solid #9c5229',
                    color: '#9c5229',
                    backgroundColor: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    marginRight: '2%',
                    padding: '5%',
                    whiteSpace: 'nowrap',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    getProducts();
                    setCheckout(true);
                  }}
                >
                  Repeat Order
                </button>
              )}
              <Expand
                expand={expanded}
                onClick={handleExpandClick}
                className={classes.expand}
              >
                <ExpandMore />
              </Expand>
            </div>
          }
          title={
            <Typography
              className={classes.orderText}
              variant="h6"
              component="h4"
            >
              Total: Rs.{total}
            </Typography>
          }
          subheader={
            <div>
              <Typography className={classes.orderText} variant="subtitle1">
                Ordered on: {orderedOn.slice(0, 10)}
              </Typography>
              <Typography className={classes.orderText} variant="subtitle1">
                Order Status:&nbsp;{orderStatus}
              </Typography>
            </div>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead className={classes.tHead}>
                  <TableRow>
                    <TableCell align="left">
                      <Typography
                        variant="h6"
                        component="p"
                        className={classes.orderText}
                      >
                        Item Name
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="h6"
                        component="p"
                        className={classes.orderText}
                      >
                        Price&nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="h6"
                        component="p"
                        className={classes.orderText}
                      >
                        Quantity&nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="h6"
                        component="p"
                        className={classes.orderText}
                      >
                        Sub-Total&nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map(item => {
                    return (
                      <TableRow
                        key={item.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell component="th" scope="row" align="left">
                          <Typography
                            variant="h6"
                            className={classes.orderText}
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {item.itemName}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            className={classes.orderText}
                            variant="h6"
                          >
                            Rs.{item.price}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            className={classes.orderText}
                            variant="h6"
                          >
                            {item.quantity}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            className={classes.orderText}
                            variant="h6"
                          >
                            Rs.{item.quantity * item.price}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Collapse>
      </Card>
      <CancelModal invoiceId={invoiceId} open={open} setOpen={setOpen} />
      <RemoveModal
        repeat={repeat}
        setRepeat={setRepeat}
        OSItems={OSItems}
        items={items}
        history={history}
      />
    </>
  );
}

const CancelModal = ({ invoiceId, open, setOpen }) => {
  const { allOrders, setAllOrders } = useUserStore();

  const handleCancelOrder = () => {
    axiosInstance
      .post(CANCEL_ORDER_URL, {
        orderId: invoiceId
      })
      .then(function (response) {
        setAllOrders(
          allOrders.map(order => {
            if (order.invoiceId === invoiceId) {
              order.status = 'Cancel';
            }
            return order;
          })
        );
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 280,
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="body1" sx={{ mb: 3 }}>
            Are you sure you wanna cancel the order?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <Button
              onClick={() => {
                handleCancelOrder();
                setOpen(false);
              }}
              variant="outlined"
              size="small"
            >
              Yes
            </Button>
            <Button
              onClick={() => setOpen(false)}
              size="small"
              variant="contained"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

/* Remove Modal */
const RemoveModal = ({ repeat, setRepeat, OSItems, items, history }) => {
  /* zustand vars */
  const { addToCart, setCart } = useCartStore();
  const { products } = useProductStore();
  /* zustand vars */

  /* for filtering ou Out of Stock and N/A items */

  /* for filtering ou Out of Stock and N/A items */

  /* fetch products */
  const repeatOrder = () => {
    setCart([]);
    const notOSItems = items.filter(item => {
      const temp = !OSItems.find(OSItem => OSItem.productId === item.productId);

      return temp;
    });

    console.log('notOsItems ', notOSItems);
    notOSItems.map(item => {
      const filteredProduct = products.find(product => {
        if (product.productId === item.productId) {
          return product;
        }
      });
      addToCart({
        itemId: item.productId,
        title: filteredProduct.title,
        amount: item.price,
        quantity: filteredProduct.quantity,
        image: filteredProduct.image,
        qty: item.quantity,
        units: filteredProduct.units
      });
    });
    history.push('/cart');
    toast.success('items added to cart');
  };

  /*css style*/
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 4
  };
  /*css style*/

  return (
    <div>
      <Modal
        open={repeat}
        onClose={() => setRepeat(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="body1" sx={{ mb: 3 }}>
            Oops!{' '}
            <strong>
              {OSItems.map(item => {
                return item.itemName + ' ';
              })}
            </strong>
            {OSItems.length === 1 ? 'is' : 'are'} OUT-OF-STOCK
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <Button
              onClick={() => {
                repeatOrder();
                setRepeat(false);
              }}
              variant="contained"
              size="small"
            >
              Remove&continue
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

/* Remove Modal */

export default withRouter(SingleOrder);
