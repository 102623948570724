import React from 'react';
import './Policy.css';
const Policy = () => {
  return (
    <>
      <div className="policy">
        <h3>RER Mandi Privacy Policy</h3>
        <div className="policy-div">
          <h5>1. What is the RER Mandi ?</h5>
          <p>
            ROOTENROUTE AGRIDIGITAL PRIVATE LIMITED, a company incorporated
            under the laws of India, having its registered office at
            H.N0.15-24-314/3/306, ROAD NO.4 KPHB Colony, Hyderabad,Telangana -
            India (“Company”) has, as a part of it objective, created a website
            [and mobile application] “RER Mandi” that facilitates it users
            registered on the RER Mandi, to buy fresh fruits and vegetables in
            Hyderabad (“Services”).
          </p>
        </div>
        <div className="policy-div">
          <h5>2. What is this Privacy Policy?</h5>
          <p>
            This privacy policy (the “Privacy Policy”) describes how and why the
            RER Mandi collects, uses and discloses the information provided by
            the users. All users shall be bound by this Privacy Policy and the
            RER Mandi shall not use any information supplied by customers except
            in accordance with this Privacy Policy. In order to use and access
            the RER Mandi, it is necessary for the users to agree to and accept
            the terms and conditions provided for under this Privacy Policy
            along with the terms of use. The users who do not agree with the
            terms set out in this Privacy Policy and the terms of use are
            advised to refrain from accepting them and are advised to refrain
            from using any Services. The users’ visit to and/or use of the RER
            Mandi and any dispute over privacy is subject to this Privacy Policy
            and the terms of use.
          </p>
        </div>
        <div className="policy-div">
          <h5>3. Why does the RER Mandi have this Privacy Policy?</h5>
          <p>
            This Privacy Policy is published pursuant to: a. Section 43A of the
            Information Technology Act, 2000; b. Regulation 4 of the Information
            Technology (Reasonable Security Practices and Procedures and
            Sensitive Personal Data or Information) Rules, 2011 (“SPDI Rules”);
            and c. Regulation 3(1) of the Information Technology (Intermediaries
            Guidelines) Rules, 2011. In this Privacy Policy, the Company will
            disclose what kind of information is collected from the customers.
            It will also specify why the RER Mandi collects this information,
            the modes and means by which it will be used, and the entities or
            persons who will have access to this information.
          </p>
        </div>
        <div className="policy-div">
          <h5>
            4. What personal information do we collect from the people that
            visit our app?
          </h5>
          <p>
            ● Financial Information: In the course of transacting on the Root En
            Route, users may be required to pay with a credit card,
            wire-transfer, or debit card through the RER Mandi’s third-party
            payment gateway provider or any other platform. This third-party
            payment gateway provider may be required to collect certain
            Financial Information from users including, but not restricted to,
            their Financial Information. The Financial Information so collected
            will only be used for billing and payment for the sale of the
            Services through the RER Mandi. The processes of verifying the
            Financial Information will be entirely conducted by the user and the
            RER Mandi shall have no part in such processes. The Financial
            Information collected from the users is transacted through secure
            digital platforms of approved payment gateways which are under
            encryption, thereby complying with reasonably expected technology
            standards. While the RER Mandi shall make reasonable endeavours to
            ensure that the user’s Personal Information and the Financial
            Information is duly protected by undertaking security measures
            prescribed under applicable laws, the user is strongly advised to
            exercise discretion while providing Personal Information or
            Financial Information while using the Services given that the
            Internet is susceptible to security breaches.
            <br />
            ● Personal Information/Personally Identifiable Information: Without
            the need for further, repeated consents (aside from the acceptance
            of this Privacy Policy) by the user, Personal Information,
            Personally Identifiable Information and/or Financial Information
            shall be collected by the RER Mandi on an ongoing basis, for one or
            more of the following reasons: a. To identify the User, to
            understand his/her/its needs and resolve disputes, if any; b. To set
            up, manage and facilitate the offer of Products, and to enhance the
            Services to meet the user’s requirements; c. To provide ongoing
            service; d. To meet legal and regulatory requirements; e. To resolve
            technical issues and troubleshoot problems; f. To aid the Root En
            Route in collecting money from users for transactions carried out on
            the RER Mandi; g. To keep users updated on the RER Mandi’s
            promotions and offers; h. To customize user experience; i. To detect
            and protect the RER Mandi from error, fraud and other criminal
            activities; j. To enforce the terms of use; and k. Other reasons
            which, prior to being put into effect, shall be communicated to the
            users through an update carried out to this Privacy Policy.
            <br />
            ● Automatically Generated Information When a user visits the Root En
            Route, it automatically receives the following information: a.
            Uniform resource locator of the site from which such user visits the
            RER Mandi; b. Details of the website such User is visiting on
            leaving the RER Mandi; c. The internet protocol (“IP”) address of
            each user’s computer operating system; d. Type of web browser the
            user is using, email patterns; and e. The user’s internet service
            provider.
            <br />
            ● Locations The RER Mandi may also access, collect, store and/or
            monitor information regarding the user’s location, which includes
            but is not limited to his/her/its global positioning system
            coordinates vis-à-vis the device used to access the RER Mandi. The
            location data does not collect or share any personally identifiable
            information about the use
            <br />● Telephone calls The RER Mandi may keep records of telephone
            calls received from and made to users for the purpose of
            administration of Services, research and development, training,
            business intelligence, business development, or for user
            administration. The RER Mandi may share the telephone records with
            third parties when required by law or when required to provide or
            facilitate the User with the Services.
          </p>
        </div>
        <div className="policy-div">
          <h5>5. When do we collect information?</h5>
          <p>
            We collect information from the user when the user registers on the
            RER Mandi, place an order, subscribe to a newsletter or enter
            information on our application. We may offer email, app
            notifications, short message service (sms), multimedia message
            service (mms) or other forms of communication to share information
            with the about certain promotions or features the application may
            choose to offer or about our affiliates, subsidiaries, business
            partners, advertisers and sponsors. You may receive such
            communication when you have registered as a user.
          </p>
        </div>
        <div className="policy-div">
          <h5>6. Who has access to your information?</h5>
          <p>
            ● Employees and Authorised Personnel All information that is
            collected, stored and disclosed to the RER Mandi by the users is
            accessible only to authorised personnel and employees on a
            need-to-know basis. All employees and data processors, who have
            access to and are associated with the processing of Personal
            Information or Financial Information provided by users are obliged
            to respect the confidentiality of every user’s Personal Information
            or Financial Information.
            <br />
            ● Government Institutions or Authorities The RER Mandi may be
            required to disclose Personal Information or Financial Information
            to governmental institutions or authorities under any law or
            judicial decree. The RER Mandi may also do so in its sole
            discretion, deems it necessary in order to protect its rights or the
            rights of others, to prevent harm to persons or property, to fight
            fraud and credit risk, or to enforce or apply the terms of use. The
            RER Mandi shall not be responsible for the manner or method in which
            such institutions and authorities use or disclose such information.
            <br />
            ● Other Parties The RER Mandi may share/use Personal Information and
            Personally Identifiable Information provided by users with the other
            parties for the purposes of: a. providing services, technical
            support, etc. in relation to the Services, and enhancing User
            experience; or b. detecting and preventing identity theft, fraud or
            any other potentially illegal acts; or c. monitoring and enhancing
            user interest and engagement, including through promotional
            activity, personal messages to users using Personally Identifiable
            Information provided by users, etc; or d. processing the purchase of
            Services on the RER Mandi.
            <br />● Third-Party Sites The RER Mandi does not exercise control
            over the websites displayed as search results or links from within
            the Services. These other sites may place their own cookies or other
            files on the users’ computer, collect data or solicit Personal
            Information or Financial Information from the users, for which the
            RER Mandi shall not be held responsible or liable. The RER Mandi
            does not make any representations concerning the privacy practices
            or policies of such third parties or terms of use of such websites,
            nor does the RER Mandi guarantee the accuracy, integrity, or quality
            of the information, data, text, software, sound, photographs,
            graphics, videos, messages or other materials available on such
            websites. The inclusion or exclusion does not imply any endorsement
            by the RER Mandi of such websites, the websites’ provider, or the
            information on the website.
          </p>
        </div>
        <div className="policy-div">
          <h5>7. How do we use your information?</h5>
          <p>
            We may use the information we collect from the user when the user
            registers, makes a purchase, signs up for our newsletter, responds
            to a survey or marketing communication, surfs the application, or
            uses certain other application features in the following ways: a. To
            personalize your experience and to allow us to deliver the type of
            content and product offerings in which you are most interested. b.
            To improve our application in order to better serve you. c. To allow
            us to better service you in responding to your customer service
            requests. d. To administer a contest, promotion, survey or other
            application feature. e. To quickly process your transactions. To ask
            for ratings and reviews of services or products
          </p>
        </div>
        <div className="policy-div">
          <h5>8. How do we protect your information?</h5>
          <p>
            The Company has implemented security policies, rules and technical
            measures, as required under applicable law including firewalls,
            transport layer security and other physical and electronic security
            measures to protect the Financial Information and Personal
            Information that it has under its control from unauthorised access,
            improper use or disclosure, unauthorised modification and unlawful
            destruction or accidental loss. While the RER Mandi is committed to
            maintaining confidentiality of all Personal Information, Personally
            Identifiable Information and Financial Information, it shall not be
            responsible for any breach of security or for any action of any
            third parties that receive Users’ personal data or events that are
            beyond the reasonable control of the RER Mandi including, acts of
            government, computer hacking, unauthorised access to computer data
            and storage device, computer crashes, breach of security and
            encryption, etc. All information collected from the Users by the RER
            Mandi is maintained in electronic form on servers and/or cloud
            systems and shall be accessible by certain employees of the Company.
            The User information may also be converted to physical form from
            time to time. Regardless of the manner of storage, the RER Mandi
            shall make commercially reasonable endeavours to ensure that the
            User information is rendered confidential and will disclose User
            information only in accordance with the terms of this Privacy
            Policy.
          </p>
        </div>
        <div className="policy-div">
          <h5>9. Do we use ‘cookies’?</h5>
          <p>
            Like any other website, Root En Route uses 'cookies'. These cookies
            are used to store information including visitors' preferences, and
            the pages on the website that the visitor accessed or visited. The
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information. Users can choose to have their computer warn them each
            time a cookie is being sent, or users can choose to turn off all
            cookies. Users do this through their browser settings. Since
            browsers are a little different, users may look at their browser’s
            help menu to learn the correct way to modify their cookies. If users
            turn cookies off, some of the features that make users’ application
            experience more efficient may not function properly.
          </p>
        </div>
        <div className="policy-div">
          <h5>10. Changes in Privacy Policy</h5>
          <p>
            We reserve the right to modify this privacy policy at any time, so
            please review it frequently. Changes and clarifications will take
            effect immediately upon their posting on the application. If we make
            material changes to this policy, we will notify you here that it has
            been updated, so that you are aware of what information we collect,
            how we use it, and under what circumstances, if any, we use and/or
            disclose it.
          </p>
        </div>
      </div>
    </>
  );
};
export default Policy;
