import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import './AboutUs.css';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(theme => ({
  backBtn: {
    position: 'absolute',
    top: '3%',
    left: '10%',
    color: '#9c5229',

    '@media (max-width:560px)': {
      top: '15px',
      left: '8px',
      fontWeight: 'bold'
    }
  }
}));

const AboutUs = () => {
  const classes = useStyles();
  let history = useHistory();
  return (
    // <div className="aboutPage">
    // <IconButton
    //   style={{ margin: '5% 0 0 15%' }}
    //   onClick={() => history.push('/')}
    // >
    //   <ArrowBackIcon style={{ fontSize: '25px' }} />
    // </IconButton>
    <div className="about">
      <IconButton className={classes.backBtn} onClick={() => history.push('/')}>
        <ArrowBackIcon style={{ fontSize: '25px' }} />
      </IconButton>
      <div className="aboutUs">
        <div className="content">
          <h3>About Us</h3>
          <p>
            RER Mandi aims to empower local farmers, by working closely as an
            agri-fresh supply company with small-scale farmers to provide
            freshly harvested green groceries and help farmers increase their
            revenue with minimal wastage.{' '}
          </p>
          <p>
            Our focus is on delivering the widest range of best quality fruits
            and vegetables at effective prices. We understand how important it
            is to get fresh fruits and veggies. We at Root En
            Route assure our customers to cut down the grocery expenses by a
            great margin and increase farmer’s income to the next level. We
            ensure that every product is delivered with care and customer
            preferences. So, you can always expect great quality with wholesome
            amount of quantity.
          </p>
        </div>
      </div>
      <div className="FC">
        <div className="farmers">
          <h3>Happy Farmers</h3>
          <ul>
            <li>Farm fresh produce</li>
            <li>Independent growers</li>
            <li>Diversifying economy</li>
            <li>Better utilization</li>
            <li>tackle the scarcity</li>
          </ul>
        </div>
        <div className="customers">
          <h3>Satisfied Customers</h3>
          <ul>
            <li>Steady supply</li>
            <li>Quality standardization</li>
            <li>No-Hassel delivery</li>
            {/* <li>No minimum cart value</li> */}
            <li>Free delivery</li>
          </ul>
        </div>
      </div>
      <div className="contact">
        <p>Any queries? we are ready to answer.</p>
        <h3 style={{ color: '#9c5229' }}>Contact Us</h3>
        <div className="contact-details">
          <div className="email">
            <h5 style={{ color: '#9c5229' }}>
              <EmailIcon />
            </h5>
            <p style={{ margin: '0.4rem 0' }}>Email</p>
            <p>
              <a
                style={{
                  listStyle: 'none',
                  textDecoration: 'none',
                  color: '#333'
                }}
                href={`mailto:${process.env.REACT_APP_EMAIL_ID}`}
              >
                info@rer-mandi.com
              </a>
            </p>
          </div>
          <div className="phone">
            <h5 style={{ color: '#9c5229' }}>
              <PhoneIcon />
            </h5>
            <p style={{ margin: '0.4rem 0' }}>Phone Numbers</p>
            <p style={{ color: '#333' }}>+91 7013649840</p>
          </div>
        </div>
      </div>
      <div>
        <p style={{ textAlign: 'center' }}>
          No.2-57,Pedda Amberpet Village ,Hayathnagar Mandal,Ranga Reddy
          District,501505
        </p>
      </div>
    </div>
    // </div>
  );
};
export default AboutUs;
