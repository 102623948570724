import React, { useEffect, useState } from 'react';
import useCartStore from '../../Store/CartStore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Remove from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { styled, withTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: '15px',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    },
    margin: ' 2px'
  },
  typo: {
    fontSize: '15px',
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      margin: 0
    }
  },
  helperText: {
    fontSize: 'clamp(.65rem, 1vw, 1rem)',
    color: 'green',
    whiteSpace: 'nowrap'
  }
}));

function SingleCartItem({
  itemId,
  title,
  quantity,
  minQuantity,
  newQuantity,
  image,
  amount,
  units
}) {
  // zustand variables
  const {
    findById,
    cart,
    increaseQuantity,
    decreaseQuantity,
    removeFromCart,
    setCartQuantity
  } = useCartStore();
  // const [cartQuantity, setCartQuantity] = useState(0);
  const [cartQty, setCartQty] = useState();
  // zustand variables

  useEffect(() => {
    setCartQty(findById(itemId)?.newQuantity);
  }, [cart]);

  // console.log(cart, cartQuantity);

  const Img = styled(withTheme('img'))(props => ({
    width: '150px',
    objectFit: 'cover',
    display: 'block',
    [props.theme.breakpoints.down('md')]: {
      width: '100px'
    }
  }));
  const Button = styled(withTheme('div'))(props => ({
    border: '1px solid #9c5229',
    padding: '3px 6px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '15px'
  }));

  const classes = useStyles();
  return (
    <Box sx={{ boxShadow: 2, marginBottom: '20px', backgroundColor: 'white' }}>
      <Grid
        container
        lg={12}
        md={12}
        xs={12}
        sm={12}
        justifyContent="space-around"
        alignItems="center"
        spacing={3}
        style={{ padding: '10px ' }}
      >
        <Grid item lg sm md xs={6}>
          <Img src={image} alt="" />
        </Grid>
        <Grid item lg sm md xs={6}>
          <Typography
            className={classes.typo}
            style={{ color: 'black', fontWeight: '400' }}
          >
            {title}
          </Typography>

          <Typography className={classes.typo} style={{ color: 'grey' }}>
            {quantity} {units}
          </Typography>
        </Grid>
        <Grid item lg sm md xs={3}>
          <Typography className={classes.typo} style={{ color: 'black' }}>
            Rs.{Math.ceil(newQuantity * amount)}
          </Typography>
        </Grid>
        <Grid item lg sm md xs={6}>
          {/* <Button>
            <Remove
              className={classes.icon}
              style={{ marginLeft: ' 3px', color: 'black', cursor: 'pointer' }}
              onClick={event => {
                event.preventDefault();
                decreaseQuantity(itemId, amount);
              }}
            />
            <Typography
              className={classes.typo}
              variant="h5"
              style={{
                cursor: 'pointer',
                color: '#9c5229',
                fontWeight: '500',
                margin: '0 5px'
              }}
            >
              {qty}
            </Typography>
            <AddIcon
              className={classes.icon}
              style={{ marginRight: ' 3px', color: 'black', cursor: 'pointer' }}
              onClick={event => {
                event.preventDefault();
                increaseQuantity(itemId, amount);
              }}
            />
          </Button> */}
          {/* <Input
            value={cartQty}
            onChange={e => {
              setCartQty(e.target.value);
              setCartQuantity(itemId, e.target.value);
            }}
          /> */}
          <TextField
            sx={{ width: '100%', margin: '0 auto 0.5em' }}
            error={cartQty < minQuantity || cartQty > 999}
            label="Qty"
            value={cartQty}
            type="number"
            size="small"
            helperText={
              cartQty < minQuantity
                ? `Min Qty. is ${minQuantity}`
                : cartQty > 999 && `Max. Qty. is ${999}`
            }
            FormHelperTextProps={{ className: classes.helperText }}
            onChange={e => {
              let newNum = e.target.value;
              // if (newNum < 1) {
              //   newNum = null;
              // }
              // if (newNum < minQuantity) {
              //   newNum = minQuantity;
              // }
              // if (newNum > 999) {
              //   newNum = 999;
              // }
              setCartQty(parseInt(newNum, 10));
              setCartQuantity(itemId, newNum);
            }}
          />
        </Grid>
        <Grid item lg sm md xs={3}>
          <IconButton style={{ cursor: 'pointer' }}>
            <DeleteOutlineOutlined
              style={{ color: '#9c5229' }}
              onClick={event => {
                event.preventDefault();
                removeFromCart(itemId);
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
export default SingleCartItem;
