import React from 'react';
import './ShippingPolicy.css';
const ShippingPolicy = () => {
  return (
    <div className="policy">
      <div className="policy-div">
        <h3>Shipping Policy</h3>
        <p>
          Orders placed before 12 AM will be delivered next day before noon. For
          any concerns about your order write to us at
          <a
            href="mailto:Support@root-enroute.com"
            style={{ margin: '0 3px', color: '#9c5229' }}
          >
            info@rer-mandi.com
          </a>
          Refunds for pre-paid orders can take 5-7 working days upon
          cancellation.
        </p>
      </div>
    </div>
  );
};
export default ShippingPolicy;
