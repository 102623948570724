import create from 'zustand';
import { persist } from 'zustand/middleware';
import { toISOLocal } from '../helpers';

const useCartStore = create(
  persist(
    (set, get) => ({
      cart: [],
      // subTotal: () => {
      //   return get().cart.length === 0
      //     ? 0
      //     : get().cart.reduce(
      //         (price, cartItem) =>
      //           Math.round(price + cartItem.amount * cartItem.qty),
      //         0
      //       );
      // },

      findById: itemId => {
        return get().cart.find(cartItem => cartItem.itemId === itemId);
      },
      setCart: cart => {
        set({ cart });
      },
      addToCart: cartItem => {
        set({ cart: [...get().cart, cartItem] });
        /*set({ subTotal: (get().subTotal += cartItem.price) });*/
      },
      /*totalPrice*/
      /* totalPrice : get().cart.reduce(
    (price, cartItem) => price + (cartItem.qty * cartItem.amount),
    0
  ),*/
      /*totalPrice*/
      //setCartTotal: totalPrice => set({ totalPrice }),
      removeFromCart: itemId => {
        set({
          cart: get().cart.filter(cartItem => cartItem.itemId !== itemId)
        });
        /*set({ subTotal: (get().subTotal -= price * qty) });*/
      },
      increaseQuantity: (itemId, amount) => {
        set({
          cart: get().cart.map(cartItem =>
            cartItem.itemId === itemId
              ? { ...cartItem, qty: cartItem.qty + 1 }
              : cartItem
          )
        });
        /*set({ subTotal: (get().subTotal += amount) });*/
      },
      setCartQuantity: (itemId, quantity) => {
        set({
          cart: get().cart.map(cartItem =>
            cartItem.itemId === itemId
              ? { ...cartItem, newQuantity: quantity }
              : cartItem
          )
        });
      },
      decreaseQuantity: (itemId, amount) => {
        set({
          cart: get().cart.map(cartItem =>
            cartItem.itemId === itemId
              ? { ...cartItem, qty: cartItem.qty - 1 }
              : cartItem
          )
        });
        /*set({ subTotal: (get().subTotal -= amount) });*/
        set({
          cart: get().cart.filter(cartItem => cartItem.qty !== 0)
        });
      },
      orderInstructions: {
        instructions: '',
        deliveryDate: toISOLocal(new Date()).slice(0, 10),
        timeSlot: '6am-12pm'
      },
      setOrderInstructions: orderInstructions => set({ orderInstructions })
    }),
    {
      name: 'cart-mgmt'
    }
  )
);

export default useCartStore;
