import React, { useLayoutEffect, useState } from 'react';
import { axiosInstance } from '../../../axios_assets';
import './Slideshow.css';
import d101 from './Desktop Slides/free_delivery.jpg';
import d102 from './Desktop Slides/rer_mandi_map.jpg';
import d103 from './Desktop Slides/customizations_and_colaborations.jpg';
import d104 from './Desktop Slides/get_regular_supply.jpg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useWindowSize } from '../../../customHooks';

const dSlides = [d101, d102, d103, d104];
const mSlides = [d101, d102, d103, d104];

/*Slideshow*/
const Slideshow = () => {
  /* active slide value */
  const [index, setIndex] = React.useState(0);

  /* current window size */
  const width = useWindowSize();

  /* display slide by index */
  const showSlideForward = () => {
    if (index < (width < 600 ? mSlides.length - 1 : dSlides.length - 1)) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  };

  const showSlideBackward = () => {
    if (index > 0) {
      setIndex(index - 1);
    } else {
      setIndex(width < 600 ? mSlides.length - 1 : dSlides.length - 1);
    }
  };

  // change slide after 5 sec interval
  React.useEffect(() => {
    let slider = setInterval(showSlideForward, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index, width]);

  return (
    <>
      <div className="container-slider">
        <ArrowBackIosIcon
          className="arrow"
          style={{ fontSize: '3vw' }}
          onClick={showSlideBackward}
        />
        <div className="slide">
          <img
            className="slider-img"
            src={width < 600 ? mSlides[index] : dSlides[index]}
            alt="slides"
          />
        </div>

        <ArrowForwardIosIcon
          className="arrow"
          style={{ fontSize: '3vw' }}
          onClick={showSlideForward}
        />
      </div>

      <div className="dots">
        {(width < 600 ? mSlides : dSlides).map((dSlide, ind) => (
          <div
            className={`inactive ${index === ind && 'active'} `}
            onClick={() => setIndex(ind)}
          ></div>
        ))}
      </div>
    </>
  );
};
export default Slideshow;
/*Slideshow*/
