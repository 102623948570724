import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './Checkout.css';
import AddressPopup from '../Address/AddressPopup';
import Payment from '../Payment/Payment';
import useUserStore from '../../Store/UserStore';
import useCheckoutStore from '../../Store/CheckoutStore';
import useMiscStore from '../../Store/MiscStore';
import usePaymentStore from './../../Store/PaymentStore';
import { ArrowForwardIos, ArrowBack } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { axiosInstance } from '../../axios_assets';
import Header from '../Header/Header';
import CircularProgress from '@mui/material/CircularProgress';

/*constants*/
const PAYMENT_URL = `${process.env.REACT_APP_PAYMENT_KEY}`;
const ADDRESS_URL = `${process.env.REACT_APP_ALL_ADDRESS_KEY}`;
/*constants*/

/*Checkout*/
const Checkout = () => {
  /*zustand variables*/
  const { addAddress, setAddAddress, paymentLoading } = useMiscStore();
  const { details, setDetails, setOrderAddressId } = useCheckoutStore();
  const { userCreds } = useUserStore();
  const { showPayment, setShowPayment } = usePaymentStore();
  const setPaymentKey = usePaymentStore(state => state.setKey);

  /*zustand variables*/

  const history = useHistory();

  /* fetch user's saved addresses */
  const getAllAddress = () => {
    axiosInstance
      .get(ADDRESS_URL, {
        phoneNumber: userCreds.phone
      })
      .then(function (response) {
        setDetails(response.data);
        //console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  /* fetch user's saved addresses */

  /* fetch payment key */
  const getPaymentDetails = () => {
    axiosInstance
      .post(PAYMENT_URL)
      .then(function (response) {
        setPaymentKey(response.data);
        //console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  /* fetch payment key */

  useEffect(() => {
    getAllAddress();
  }, [details.length]);

  useEffect(() => {
    getPaymentDetails();
  }, []);

  if (paymentLoading) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          backgroundColor: 'lignt grey',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 100
        }}
      >
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="checkout">
        <IconButton onClick={() => history.goBack()} sx={{ maxWidth: '1.5em' }}>
          <ArrowBack />
        </IconButton>
        <div className="checkout-address-block">
          <div className="heading">Select Delivery Address</div>
          <div className="checkout-block">
            {details
              ? details.map(address => {
                  return (
                    <div
                      className="checkout-address"
                      key={address.id.toString()}
                    >
                      <input type="radio" name="radio-btn" id="radio-btn" />

                      <div className="checkout-details">
                        <div className="name">{address.name}</div>
                        <div>{address.phone}</div>
                        <div>
                          {address.houseNo},{address.roadNameAreaColony}
                        </div>
                        <div>{address.landmark}</div>
                        <div>
                          {address.city},{address.state}
                        </div>
                        <div>{address.pinCode}</div>
                        <div>{address.country}</div>
                      </div>
                      <br />
                      <label hidden>
                        <Button
                          variant="contained"
                          style={{
                            background: '#9c5229',
                            color: 'white',
                            cursor: 'pointer',
                            padding: '3px 9px'
                          }}
                          onClick={() => {
                            setShowPayment(true);
                            setOrderAddressId(address.id);
                          }}
                        >
                          Delivery To This Address
                        </Button>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setAddAddress(!addAddress);
            }}
            style={{
              background: '#9c5229',
              color: 'white',
              cursor: 'pointer',
              padding: '1% 2%',
              margin: '2% 1%'
            }}
          >
            Add New Address
            <ArrowForwardIos
              style={{ color: 'white', fontSize: '15px', paddingLeft: '1%' }}
            />
          </Button>
        </div>
        <AddressPopup />
        {showPayment && <Payment />}
      </div>
    </>
  );
};
/*Checkout*/

export default Checkout;
