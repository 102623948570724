import React from 'react';
import { withRouter } from 'react-router-dom';
import useCheckoutStore from '../../Store/CheckoutStore';
import { Card, Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { red } from '@mui/material/colors';
import { axiosInstance } from '../../axios_assets';

/* constants */
const DELETE_ADDRESS_URL = `${process.env.REACT_APP_DELETE_ADDRESS_KEY}`;
/* constants */

/* Address Card Styles */
const AddressCard = styled(Card)(({ theme }) => ({
  padding: '10px',
  margin: '.5em',
  boxShadow: '.5px .5px 1px 1px hsl(0, 0%, 90%)',
  width: '50vw',
  display: 'flex',
  margin: '5px auto',
  justifyContent: 'space-between',
  '& div': {
    textAlign: 'left',
    color: 'black'
  },
  [theme.breakpoints.down('md')]: {
    width: '60vw'
  },
  [theme.breakpoints.down('sm')]: {
    width: '90vw'
  }
}));

// mui classes
const useStyles = makeStyles(theme => ({
  delete: {
    margin: '5px',
    alignSelf: 'flex-start',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: red[500]
    }
  },
  illustration: {
    width: '30em',
    opacity: '0.8',
    paddingTop: '15%',
    '@media(max-width:800px)': {
      width: '55vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      paddingTop: 0,
      transform: 'translateY(-10%)'
    }
  },
  illustrationBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '45vh',
    '@media(max-width:800px)': {
      height: '60vh'
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  iTitle: {
    transform: 'translateY(-350%)',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(-420%)',
      fontSize: '.85em'
    }
  },
  iDesc: {
    transform: 'translateY(-345%)',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(-410%)',
      fontSize: '.85em'
    }
  }
}));

function AddressView({ history }) {
  const { details } = useCheckoutStore();

  const classes = useStyles();

  // const handleAddressDeleteRequest = addressId => {
  //   axiosInstance
  //     .post(DELETE_ADDRESS_URL, {
  //       id: addressId
  //     })
  //     .then(function (response) {
  //       //console.log(response.data);
  //       window.location.reload();
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //     });
  // };

  return (
    <>
      <Box>
        {details.length < 1 ? (
          <div className={classes.illustrationBox}>
            <img
              src="illustration\bgs address.png"
              alt="address illustration"
              className={classes.illustration}
            />
            <Typography variant="body1" className={classes.iTitle}>
              No Address Available!
            </Typography>
            <Typography variant="body1" className={classes.iDesc}>
              Please add a delivery address.
            </Typography>
            {/* <Button variant="contained" sx={{ transform: 'translateY(-100%)' }}>
              Add Address
            </Button> */}
          </div>
        ) : (
          details.map(address => {
            return (
              <Grid
                container
                spacing={2}
                key={address.id.toString()}
                justifyContent="center"
                style={{ overflowY: 'auto' }}
              >
                <Grid item sm={30} md={40} lg={50} xl={60}>
                  <AddressCard>
                    <Box>
                      <Typography variant="button" sx={{ fontSize: '0.8rem' }}>
                        {address.name}
                      </Typography>
                      <br />
                      <Typography variant="button" sx={{ fontSize: '0.8rem' }}>
                        {address.phone}
                      </Typography>
                      <br />
                      <Typography
                        variant="button"
                        sx={{ fontWeight: '400', fontSize: '0.8rem' }}
                      >
                        {address.houseNo}
                      </Typography>
                      {/* <Typography variant="button">
                        dhyaneshwar paduka chouk, model colony, shivaji nagar
                      </Typography> */}
                      <br />
                      <Typography
                        variant="button"
                        sx={{ fontWeight: '400', fontSize: '0.8rem' }}
                      >
                        {address.roadNameAreaColony}
                        {address.landmark}
                      </Typography>
                      <br />
                      <Typography variant="button" sx={{ fontSize: '0.8rem' }}>
                        {address.city},{address.state},{address.pinCode}
                      </Typography>
                    </Box>
                    {/* <IconButton
                    onClick={() => handleAddressDeleteRequest(address.id)}
                    className={classes.delete}
                    >
                      <DeleteRoundedIcon />  
                    </IconButton> */}
                  </AddressCard>
                </Grid>
              </Grid>
            );
          })
        )}
      </Box>
    </>
  );
}

export default withRouter(AddressView);
