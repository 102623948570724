import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import useUserStore from '../../Store/UserStore';
import { axiosInstance } from '../../axios_assets';
import { validateAddressForm } from '../../helpers';
import useMiscStore from '../../Store/MiscStore';
import useCheckoutStore from '../../Store/CheckoutStore';
import useMapStore from '../../Store/MapStore';

/*Constants*/
const ADD_ADDRESS_URL = `${process.env.REACT_APP_ADD_ADDRESS_KEY}`;
const PINCODE_URL = `${process.env.REACT_APP_PINCODE_KEY}`;
const ADDRESS_URL = `${process.env.REACT_APP_ALL_ADDRESS_KEY}`;
/*Constants*/

export default function Proceed() {
  const { userCreds, userData } = useUserStore();
  const { setAddressView, setAddAddress } = useMiscStore();
  const { setDetails } = useCheckoutStore();
  const { mapState, setMapState } = useMapStore();

  const [errors, setErrors] = useState({});
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const [values, setValues] = useState({
    fullName: `${userData.firstName} ${userData.lastName}`,
    phone: userData.phoneNumber,
    houseNo: '',
    pinCode: mapState.currPincode,
    landmark: mapState.address,
    latitude: mapState.latitude,
    longitude: mapState.longitude,
    plusCode: mapState.plusCode
  });
  // const [pinError, setPinError] = useState(!mapState.currPincode);

  const textFieldStyles = { margin: '3% 5% 0', width: '90%' };
  const errorStyles = {
    color: 'crimson',
    marginLeft: '6%',
    fontFamily: 'Calibri, sans-serif'
  };
  const buttonStyles = {
    background: 'var(--primary)',
    color: 'white',
    cursor: 'pointer',
    margin: '3% auto'
  };

  useEffect(() => {
    console.log('mapState:', mapState);
    console.log('values: ', values);
  }, [mapState, values]);

  useEffect(() => {
    // checking for errors and proceeding to adding address in db
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      let reqBody = {
        phoneNumber: userCreds.phone,
        name: values.fullName,
        phone: values.phone,
        houseNo: values.houseNo,
        landmark: values.landmark,
        city: 'Hyderabad',
        state: 'Telangana',
        pinCode: values.pinCode,
        country: 'India',
        latitude: values.latitude,
        longitude: values.longitude,
        plusCode: values.plusCode
      };
      console.log('proceed req body: ', reqBody);
      axiosInstance
        .post(ADD_ADDRESS_URL, reqBody)
        .then(function (response) {
          console.log('address' + response.data);
          axiosInstance
            .get(ADDRESS_URL, {
              phoneNumber: userCreds.phone
            })
            .then(function (response) {
              setDetails(response.data);
              console.log('all addresses: ', response.data);
            })
            .catch(function (error) {
              console.error(error);
            });
          setValues({
            fullName: '',
            phone: '',
            houseNo: '',
            landmark: '',
            pinCode: '',
            latitude: '',
            longitude: '',
            plusCode: ''
          });
        })
        .catch(function (error) {
          console.error(error);
        });

      setAddAddress(false);
      setAddressView(0);
    }
  }, [errors, dataIsCorrect]);

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  /* for checking if the pin is present in db */
  const isPinWoking = () => {
    axiosInstance
      .get(`${PINCODE_URL}/${values.pinCode ? values.pinCode : 0}`)
      .then(response => {
        console.log('after validation: ', response.data);
        setErrors(validateAddressForm(values, response.data));
        setDataIsCorrect(true);
      })
      .catch(error => {
        console.error('is pinworking error msg: ' + error);
      });
  };
  /* for checking if the pin is present in db */

  return (
    <>
      <h2>Add New Address</h2>

      <div style={{ overflowY: 'auto' }}>
        <TextField
          style={textFieldStyles}
          size="small"
          label="FullName"
          value={values.fullName}
          onChange={handleChange}
          name="fullName"
          type="text"
        />
        {errors.fullName && (
          <Typography variant="subtitle2" style={errorStyles}>
            {errors.fullName}
          </Typography>
        )}
        <TextField
          style={textFieldStyles}
          size="small"
          label="Phone"
          value={values.phone}
          onChange={handleChange}
          name="phone"
          type="tel"
        />
        {errors.phone && (
          <Typography variant="subtitle2" style={errorStyles}>
            {errors.phone}
          </Typography>
        )}
        <TextField
          style={textFieldStyles}
          label="House No.,Block No.,Building Name, etc."
          size="small"
          value={values.houseNo}
          onChange={handleChange}
          name="houseNo"
          type="text"
        />
        {errors.houseNo && (
          <Typography variant="subtitle2" style={errorStyles}>
            {errors.houseNo}
          </Typography>
        )}
        <div style={textFieldStyles}>
          <Typography variant="h6" sx={{ color: '#9c5229' }}>
            Selected Address:
          </Typography>
          {values.landmark}
        </div>
        {errors.pinCode && errors.pinCode !== 'Invalid pincode' && (
          <Typography variant="subtitle2" style={errorStyles}>
            {errors.pinCode}
          </Typography>
        )}
        {!mapState.currPincode && (
          <Typography variant="subtitle2" style={errorStyles}>
            Please select a different location with pincode
          </Typography>
        )}
      </div>
      <Button
        style={{ ...buttonStyles, marginBottom: '1%' }}
        variant="contained"
        type="submit"
        onClick={isPinWoking}
      >
        Add Address
      </Button>
      <div
        className="or-line"
        style={{ textAlign: 'center', fontSize: '0.8rem' }}
      >
        <span>or</span>
      </div>
      <Button
        style={{ ...buttonStyles, marginTop: 0 }}
        variant="contained"
        type="submit"
        onClick={() => setAddressView(0)}
      >
        Choose Different Location
      </Button>
    </>
  );
}
