import create from 'zustand';
import { persist } from 'zustand/middleware';

const useMapStore = create(
  persist(
    set => ({
      /* storing map detials */
      mapState: {},
      setMapState: mapState => set({ mapState })
    }),
    {
      name: 'map Data'
    }
  )
);

export default useMapStore;
