import React, { useState, useEffect, useRef } from 'react';
import useCartStore from '../../Store/CartStore';
import useUserStore from '../../Store/UserStore';
import {
  Typography,
  Button,
  TextField,
  Box,
  InputAdornment
} from '@mui/material';
import Remove from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import './Products.css';
import { axiosInstance } from '../../axios_assets';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { setActiveLink } from 'react-scroll/modules/mixins/scroller';
import useAuthStore from '../../Store/AuthStore';
import useMiscStore from '../../Store/MiscStore';
import './Products.css';
import { FavoriteBorder, Favorite } from '@mui/icons-material';

const WISHLIST_URL = `${process.env.REACT_APP_WISHLIST_KEY}`;

const ADD_WISHLIST_URL = `${process.env.REACT_APP_ADD_WISHLIST_KEY}`;

const DELETE_WISHLIST_URL = `${process.env.REACT_APP_DELETE_WISHLIST_KEY}`;

const useStyles = makeStyles(theme => ({
  qtyBtn: {
    width: '70%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    border: '2px solid #9c5229',
    borderRadius: '30px',
    '@media (max-width:560px)': {
      width: '100%'
    }
  },
  addBtn: {
    width: '70%',
    margin: 'auto',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1em',
    padding: 0
  },
  iconBtn: {
    color: theme.palette.primary.main,
    '@media(max-width:560px)': {
      fontSize: '0.7em'
    }
  },
  actionBtn: {
    margin: '1px'
  }
}));

const SingleProduct = ({
  productId,
  title,
  quantity,
  minQuantity,
  image,
  amount,
  units,
  description,
  product_status
}) => {
  const {
    findById,
    cart,
    addToCart,
    increaseQuantity,
    decreaseQuantity,
    setCartQuantity,
    removeFromCart
  } = useCartStore();
  const isAuth = useAuthStore(state => state.isAuth);
  const { showSignUp, setShowSignUp, formView, setFormView } = useMiscStore(
    state => state
  );
  const [productQty, setProductQty] = useState();
  // const [cartQuantity, setCartQuantity] = useState(0);
  const [desc, showDesc] = useState(false);
  useEffect(() => {
    setProductQty(findById(productId)?.newQuantity);
  }, [cart]);
  const { watchList, setWatchList } = useUserStore();
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const timer = useRef();

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(timer.current);
  //   };
  // }, []);

  const handleUpdateQty = () => {
    // if (!loading) {
    setSuccess(false);
    setLoading(true);
    // timer.current = window.setTimeout(() => {
    setCartQuantity(productId, productQty);
    setSuccess(true);
    setLoading(false);
    // }, 3000);
    // }
  };

  // console.log(cart, cartQuantity);
  const classes = useStyles();
  const theme = useTheme();
  const snackbarHandler = () => {
    toast.success(`${cart.length + 1} item(s) added to cart`);
  };
  const getWatchlist = () => {
    axiosInstance
      .get(WISHLIST_URL)
      .then(response => {
        setWatchList(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {}, [isAuth]);

  const toggleWatchlist = () => {
    setDisable(true);
    if (isAuth === true) {
      if (watchList.includes(productId)) {
        axiosInstance
          .post(DELETE_WISHLIST_URL, { productId })
          .then(response => {
            if (response.data) {
              getWatchlist();
            }
            setDisable(false);
          })
          .catch(error => {
            console.error(error);
          });
      } else if (!watchList.includes(productId)) {
        axiosInstance
          .post(ADD_WISHLIST_URL, { productId })
          .then(response => {
            if (response.data) {
              toast.success('Item Added To Watchlist');
              getWatchlist();
            }
            setDisable(false);
          })
          .catch(error => {
            console.error(error);
          });
      }
    } else if (isAuth === false) {
      setShowSignUp(true);
    }
  };

  return (
    <>
      <div className="product-card">
        {product_status !== 'AT' && (
          <div className="outofstock">
            <div>OUT OF STOCK</div>
          </div>
        )}
        <span className="watchlist-button">
          {watchList.includes(productId) ? (
            <IconButton onClick={toggleWatchlist} disabled={disable}>
              <Favorite sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          ) : (
            <IconButton onClick={toggleWatchlist} disabled={disable}>
              <FavoriteBorder />
            </IconButton>
          )}
        </span>
        <img className="img" src={image} alt="productimage" />
        <div className="product-card-content">
          {description && (
            <span className="info-button alertButton">
              <IconButton onClick={() => showDesc(!desc)}>
                <InfoIcon className={classes.iconBtn} />
              </IconButton>
              <div
                style={{ textAlign: 'center', fontSize: '0.8em' }}
                className="alertText"
              >
                {description &&
                  description.split(', ').map(item => {
                    return (
                      <React.Fragment key={item.toString()}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })}
              </div>
            </span>
          )}
          <h3 className="title">{title}</h3>
          <div className="product-content">
            <p>
              {quantity} {units}
            </p>
            <p style={{ color: 'black' }}>Rs.{amount}</p>
          </div>
          {findById(productId) ? (
            <>
              <TextField
                sx={{
                  margin: '0 auto 0.5em'
                }}
                error={productQty < minQuantity || productQty > 999}
                label="Qty"
                value={productQty}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{units}</InputAdornment>
                  )
                }}
                helperText={
                  productQty < minQuantity
                    ? `Min Qty. is ${minQuantity}`
                    : productQty > 999 && `Max. Qty. is ${999}`
                }
                onChange={e => {
                  let newNum = e.target.value;
                  setProductQty(parseInt(newNum, 10));
                  setSuccess(false);
                }}
              />
              <Box sx={{ position: 'relative' }}>
                <Button
                  className={classes.addBtn}
                  variant={success ? 'outlined' : 'contained'}
                  component="span"
                  disabled={
                    isNaN(productQty) ||
                    productQty < minQuantity ||
                    productQty > 999
                  }
                  onClick={() => {
                    handleUpdateQty();
                  }}
                >
                  {success ? 'Updated' : 'Update'}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: theme.palette.primary.main,
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-5%',
                      marginLeft: '-50%'
                    }}
                  />
                )}
              </Box>
            </>
          ) : (
            <Button
              className={classes.addBtn}
              style={{
                visibility: `${
                  product_status === 'OS' || product_status === 'NA'
                    ? 'hidden'
                    : 'visible'
                } `
              }}
              variant="contained"
              onClick={() => {
                addToCart({
                  itemId: productId,
                  title: title,
                  amount: amount,
                  quantity: quantity,
                  image: image,
                  units: units,
                  minQuantity: minQuantity,
                  newQuantity: productQty ? productQty : minQuantity
                });
                snackbarHandler();
              }}
            >
              ADD
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default SingleProduct;
