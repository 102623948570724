import React, { useState } from 'react';
import './Category.css';
import useProductStore from '../../../Store/ProductStore';

/*Category*/
const Category = () => {
  const tabs = useProductStore(state => state.tabs);
  const setTabs = useProductStore(state => state.setTabs);

  const toggleStyle = index => {
    if (tabs[index].toggle === true) {
      return 'active';
    }
    if (tabs[index].toggle === false) {
      return 'inactive';
    }
  };
  const toggleClick = index => {
    let arrayCopy = [...tabs];
    arrayCopy[index].toggle
      ? (arrayCopy[index].toggle = false)
      : (arrayCopy[index].toggle = true);
    setTabs(arrayCopy);
  };

  return (
    <div className="category">
      <ul>
        {tabs.map((tab, index) => (
          <li
            key={index.toString()}
            className={toggleStyle(index)}
            onClick={() => {
              toggleClick(index);
            }}
          >
            <img src={tab.image} alt={tab.alt} draggable={false} />
            <p>{tab.id}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Category;
/*Category*/
