// apiKey: 'AIzaSyA4uH_T-b1M-HX4s8qGO8bhs9V9GGlwhCQ'
import React, { useState } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker
} from 'react-google-maps';
import Geocode from 'react-geocode';
import axios from 'axios';
import './ShowMap.css';
import { IconButton } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
const PINCODE_URL =
  'https://rer-deploy.herokuapp.com/api/v1/location/isPinWoking';

Geocode.setApiKey('AIzaSyA4uH_T-b1M-HX4s8qGO8bhs9V9GGlwhCQ');
Geocode.enableDebug();

//ShowMap
class ShowMap extends React.Component {
  //state
  state = {
    address: '',
    city: '',
    area: '',
    currPincode: '',
    state: '',
    isAvailable: false,
    zoom: 15,
    height: 400,
    mapPosition: {
      lat: 17.41562,
      lng: 78.47472
    },
    markerPosition: {
      lat: 17.41562,
      lng: 78.47472
    }
  };

  // componentDidMount
  componentDidMount = () => {
    this.getGPSLocation();
  };

  //getCity
  getCity = addressArray => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'administrative_area_level_2' === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  //getArea
  getArea = addressArray => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            'sublocality_level_1' === addressArray[i].types[j] ||
            'locality' === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  //getCurrPincode
  getCurrPincode = addressArray => {
    addressArray.reverse();
    return addressArray.find(item => item.types[0] === 'postal_code')
      ?.long_name;
  };

  //getState
  getState = addressArray => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  //getPlusCode
  getPlusCode = addressArray => {
    addressArray.reverse();
    return addressArray.find(item => item.types[0] === 'plus_code')?.long_name;
  };

  //getAddressNoPlusCode
  getAddressNoPlusCode = fAdd => {
    return fAdd?.slice(fAdd.indexOf(' ') + 1);
  };

  //onChange
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onInfoWindowClose = event => {};

  //onMarkerDragEnd
  onMarkerDragEnd = event => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        const formattedAddress = response.results[0].formatted_address,
          address = this.getAddressNoPlusCode(formattedAddress),
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          currPincode = this.getCurrPincode(addressArray),
          state = this.getState(addressArray),
          plusCode = response.plus_code.global_code.slice(4);

        console.log('OnDrag: ', currPincode, address, plusCode);
        this.props.setMapState({
          address: address || '',
          city: city || '',
          area: area || '',
          state: state || '',
          currPincode: currPincode || '',
          plusCode: plusCode || '',
          latitude: newLat,
          longitude: newLng
        });
        this.setState({
          address: address || '',
          area: area || '',
          city: city || '',
          state: state || '',
          currPincode: currPincode || '',
          markerPosition: {
            lat: newLat,
            lng: newLng
          },
          mapPosition: {
            lat: newLat,
            lng: newLng
          },
          zoom: 15
        });
      },
      error => {
        console.error(error);
      }
    );
  };

  //getGPSLocation
  getGPSLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.posError
      );
    } else {
      alert('Sorry, Geolocation is not supported by your browser!');
    }
  };

  //posError
  posError = () => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(res => {
        if (res.state === 'denied') {
          alert(
            'Enable location permissions for this website in your browser settings.'
          );
        }
      });
    } else {
      alert(
        'Unable to access your location. You can continue by submitting location manually.'
      );
    }
  };

  // Geolocation success callback fn showPosition
  showPosition = position => {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    this.setState({
      mapPosition: {
        lat,
        lng
      },
      markerPosition: {
        lat,
        lng
      },
      zoom: 15
    });
    Geocode.fromLatLng(lat, lng).then(response => {
      console.log('geocode response: ', response);
      const formattedAddress = response.results[0].formatted_address;
      const address = this.getAddressNoPlusCode(formattedAddress);
      const addressArray = response.results[0].address_components;
      const city = this.getCity(addressArray);
      const area = this.getArea(addressArray);
      const state = this.getState(addressArray);
      const currPincode = this.getCurrPincode(addressArray);
      const plusCode = response.plus_code.global_code.slice(4);
      console.log('pluscode: ', plusCode);
      this.props.setMapState({
        address: address || '',
        city: city || '',
        area: area || '',
        state: state || '',
        currPincode: currPincode || '',
        plusCode: plusCode || '',
        latitude: lat,
        longitude: lng
      });
      this.setState({
        address: address || '',
        city: city || '',
        area: area || '',
        state: state || '',
        currPincode: currPincode || '',
        markerPosition: {
          lat,
          lng
        },
        mapPosition: {
          lat,
          lng
        },
        zoom: 15
      });
    });
  };

  //isPinWoking
  isPinWoking = () => {
    axios
      .get(`${PINCODE_URL}/${this.state.currPincode}`, {
        headers: {
          Authorization:
            'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4Njg2NjIxOTU1IiwiZXhwIjoxNjUzNzI3NDAyLCJpYXQiOjE2NTExMzU0MDJ9.oZAOHXPJ6KPbdns-PCTlNTyQiY298WoPOPasFjYxM9HPRGlUVRtFtZCJh-qUiVZqzMG5rwnXysi565wyECWg3g'
        }
      })
      .then(response => {
        console.log(response.data);
        this.setState({ isAvailable: response.data });
      })
      .catch(error => {
        console.error('is pinworking error msg: ' + error);
      });
  };

  render() {
    return (
      <div>
        <AsyncMap
          isMarkerShown
          position={{
            lat: this.state.markerPosition.lat,
            lng: this.state.markerPosition.lng
          }}
          center={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng
          }}
          zoom={this.state.zoom}
          address={this.state.address}
          onMarkerDragEnd={this.onMarkerDragEnd}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4uH_T-b1M-HX4s8qGO8bhs9V9GGlwhCQ&v=3.exp&libraries=places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div style={{ height: this.state.height, position: 'relative' }} />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
        <IconButton
          style={{
            position: 'absolute',
            right: '4%',
            bottom: '32%',
            background: '#fff'
          }}
          onClick={this.getGPSLocation}
        >
          <MyLocationIcon style={{ color: '#9c5229' }} />
        </IconButton>
      </div>
    );
  }
}

//AsyncMap
const AsyncMap = withScriptjs(
  withGoogleMap(props => {
    const [IW, setIW] = useState(true);
    // const [reRender, setReRender] = useState(false);
    // React.useEffect(() => {
    //   console.log("reRender!")
    // }, [reRender]);
    return (
      <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={props.center}
        defaultOptions={{
          fullscreenControl: false,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false
        }}
      >
        <Marker
          google={props.google}
          draggable={true}
          onDragEnd={props.onMarkerDragEnd}
          position={props.position}
          onClick={() => setIW(prev => !prev)}
        >
          {IW && (
            <InfoWindow
              position={props.position}
              options={{
                maxWidth: 300
              }}
            >
              <div>
                <span
                  style={{
                    padding: 0,
                    margin: 0
                  }}
                >
                  {props.address || 'Fetching your current location...'}
                </span>
              </div>
            </InfoWindow>
          )}
        </Marker>
      </GoogleMap>
    );
  })
);

export default ShowMap;
// "google-maps-react": "^2.0.6",
// "react-google-maps":"^9.4.5",
